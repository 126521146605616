import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import { DataContext } from '../../App';
import subscribeimg from "../../../src/assets/images/subscribe.jpg"

const UploadMultipleVideos = ({ label, name, handleAddVideo, formik, isCompanyProfileEditing, videosLimit, videoCount, setHasUnsavedChanges, subscribedpalresponce }) => {
  const fileInputRef = useRef(null);
  const [previewVideos, setPreviewVideos] = useState([]);
  const [dragging, setDragging] = useState(false);
  const { planEnddates,  activeTab, setActivetab } = useContext(DataContext);
  const totalVideocount = previewVideos.length + videoCount
  const navigate = useNavigate()

  // Function to handle video files
  // const handleVideoChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   console.log("files",files)
  //   // console.log("files",files)
  //   processFiles(files);
  // };
  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

  
    // Process each file to append the thumbnail
    const filesWithThumbnails = await Promise.all(
      files.map(async (file) => {
        const thumbnail = await captureThumbnail(file);
  
        // Add the base64 thumbnail directly to the file object
        file.thumbnail = thumbnail;
  
        return file;
      })
    );
  
    // Now `filesWithThumbnails` contains all files with the thumbnail appended
    processFiles(filesWithThumbnails);
  };

  const captureThumbnail = (videoFile) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(videoFile);
  
      video.addEventListener('loadeddata', () => {
        video.currentTime = 20; // Capture the thumbnail at the 2nd second
      });
  
      video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        // Convert the canvas image to a base64 encoded string
        const thumbnail = canvas.toDataURL('image/jpeg');
  
        resolve(thumbnail);
      });
  
      video.addEventListener('error', (error) => {
        reject('Error capturing thumbnail:', error);
      });
    });
  };


  // Function to handle drag and drop
  const handleDrop = (e) => {
    setHasUnsavedChanges(true)
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  // Function to open file selector dialog
  const handleClick = () => {
    setHasUnsavedChanges(true)
    fileInputRef.current.click();
  };

  // Handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  // Handle drag leave event
  const handleDragLeave = () => {
    setDragging(false);
  };

  // Process selected or dropped files
  const processFiles = (files) => {

    const base64Strings = [];
    const maxSize = 500 * 1024 * 1024;
    let allFilesValid = true; // Flag to track if all files are valid

    files.forEach((file) => {
      if (!file.type.startsWith('video/')) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid file type',
          text: 'Please upload only video files.',
        });
        allFilesValid = false;
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          icon: 'error',
          title: 'File too large',
          text: 'Each video must be less than 500MB.',
        });
        allFilesValid = false;
        return;
      }

      if (allFilesValid) {
        base64Strings.push(file);
        setPreviewVideos((prev) => [...prev, ...base64Strings]);
      }
      // if (allFilesValid) {
      //   const reader = new FileReader();
      //   reader.onloadend = () => {
      //     const base64String = reader.result;
      //     const fileName = file.name;
      //     base64Strings.push({ base64String, fileName });
      //     // base64Strings.push(base64String);

      //     // If this is the last file, update the preview and call handleAddVideo
      //     if (base64Strings.length === files.length) {
      //       setPreviewVideos((prev) => [...prev, ...base64Strings]);

      //     }
      //   };
      //   reader.readAsDataURL(file);
      // }
    });

    if (!allFilesValid) {
      return;
    }
  };

  // Handle saving videos
  const handleSaveVideos = () => {

    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object


    if (!subscribedpalresponce) {

      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add more videos to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/plan-selection')
        }
      });

    }else if(planEndDate < currentDate){
      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    }else {

      if (totalVideocount <= videosLimit) {
        handleAddVideo(previewVideos);
        setPreviewVideos([]);
        // formik.submitForm();
      } else {
        Swal.fire({
          title: "Videos Limit Exceeded!",
          text: `You've reached the limit of ${videosLimit} for your videos. Upgrade your plan to add more videos and make your business stand out!`,
          // text: `You can only select up to ${videosLimit} videos.`,
          icon: "warning",
          confirmButtonText: "Upgrade Plan Now!",
          cancelButtonText: "Maybe Later",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/plan-selection')
            setActivetab("6")
          }
        });

      }
    };

  };

  // Handle removing a preview video
  const handleRemovePreviewVideo = (index) => {
    setHasUnsavedChanges(false)
    setPreviewVideos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div>
      <label>{label}</label>
      <br />
      <div
        style={{
          border: dragging ? '2px solid #00f' : '2px dashed #ccc',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          height: '40%',
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        className="upload-drop-zone mt-2"
      >
        {isCompanyProfileEditing ? (
          <Button disabled
             color="primary"
          >
            <Spinner size="sm" />
            <span> Uploading... </span>
          </Button>
        ) : (
          <Button type="button"
            className="btn btn"
            color="primary"
          >
            Select Videos
          </Button>
        )}
        <input
          type="file"
          name={name}
          disabled={isCompanyProfileEditing}
          accept="video/*"
          multiple
          onChange={handleVideoChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <p>or drag and drop videos here</p>
      </div>
      <div className="mt-3">
        {previewVideos.length > 0 && <h5>Preview Videos</h5>}
        <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: "hidden" }}>
          <div className="row">
            {previewVideos.map((video, index) => (
              // <div key={index} className="col-md-3 position-relative mb-3">
              <div key={index} className="col-md-6 position-relative mb-3" style={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"}}>
                <video
                  // src={video.base64String}
                  src={URL.createObjectURL(video)} 
                  className="img-fluid"
                  controls
                  style={{ height: '100px', width: 'auto', objectFit: 'cover', border: '1px solid black' }}
                  // poster={video.thumbnail || subscribeimg}
                />
                  <p
                 style={{
                  whiteSpace: "nowrap", // Prevent the text from wrapping to the next line
                  overflow: "hidden", // Hide the overflowing text
                  textOverflow: "ellipsis", // Add ellipsis (...) to indicate overflow
                  width: "100%", // Ensure the text container takes up the full width of the card
                  marginTop: "5px", // Optional: Add some spacing from the audio element
                }}
                 >
                  {video?.name}
                  {/* {video?.fileName} */}
                  </p>
                <Button
                  type="button"
                  size='xs'
                  className="btn btn-danger btn-sm position-absolute"
                  style={{ top: '0px', right: '14px' }}
                  onClick={() => handleRemovePreviewVideo(index)}
                >
                  X
                </Button>
              </div>
            ))}
          </div>
        </div>
        {/* {(previewVideos.length > 0 && totalVideocount <= videosLimit) && ( */}
        {previewVideos.length > 0 ? (
          <Button type="button"
             color="primary"
            onClick={handleSaveVideos} className="btn btn-success mt-2">
            Upload
          </Button>
        ) : ""
        }
        {/* )} */}
        {/* { !(totalVideocount <= videosLimit)  ? (
          !subscribedpalresponce ? (
            <span style={{ color: "red" }}>
              <Link to="/plan-selection" style={{ color: "red",  cursor: 'pointer',}}>
                Please subscribe plan!
              </Link>
            </span>
          ) : (
            <span style={{ color: "red" }}>You have reached your videos limit. Please upgrade your plan !</span>
          )
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};

export default UploadMultipleVideos;
