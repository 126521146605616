import React, { useEffect, useState } from "react";
import { Button, CardTitle } from "reactstrap";
import { Icon, PreviewCard, Progress, TooltipComponent } from "../../components/Component";
import { BarChart } from "../../components/partials/charts/sales/Charts";
import { getCookie, parseDateString } from "../../utils/Utils";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const TotalEvent = () => {
  const [eventdata, setEventData] = useState([]);
  const [eventdate, setEventDate] = useState([]);
  useEffect(() => {
    eventInfo();
    return () => {};
  }, []);
  const navigate = useNavigate();
  const eventInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      
      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      // console.log("responseData112",responseData)
      setEventData(responseData ? responseData?.data : []);
      setEventDate(responseData?.data?.updated_at);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var totalevent = 0;

  for (let i = 0; i < eventdata?.length; i++) {
    totalevent++;
  }

  var successfulevent = 0;
  for (let i = 0; i < eventdata?.length; i++) {
    if (
      eventdata?.[i]?.start_date_time &&
      eventdata?.[i]?.package_id &&
      eventdata?.[i]?.event_financials?.status === "4" &&
      eventdata?.[i]?.start_date_time < parseDateString(new Date())
    ) {
      successfulevent++;
    }
  }

  // var incomplete_Events = 0;
  // for (let i = 0; i < eventdata?.length; i++) {
  //   if (
  //     eventdata?.[i]?.event_financials?.status !== "4" &&
  //     eventdata?.[i]?.start_date_time < parseDateString(new Date())
  //   ) {
  //     incomplete_Events++;
  //   }
  // }

  
  

  var pending_event = 0;
  for (let i = 0; i < eventdata?.length; i++) {
    if (eventdata?.[i]?.start_date_time > parseDateString(new Date())) {
      pending_event++;
    }
  }

  const incomplete_Events = totalevent -(pending_event + successfulevent);
  
  const finalPorogressVal = ((successfulevent / totalevent) * 100).toFixed(2);
  const BtnHover = {
    hover: {
      y: 1,
    },
  };

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2 align-center">
        <CardTitle>
          <h4 className="title">Events</h4>
        </CardTitle>
        <div className="card-tools">
          <Button className="text-white px-4 p-0 btn-primary" onClick={() => navigate("/event-list")}>
            <motion.span variants={BtnHover} whileHover={"hover"}>
              <span className="sub-text text-white"> List</span>
            </motion.span>
          </Button>
        </div>
      </div>
      <div className="row text-center justify-around mt-5">
        <div className="nk-sale-data col-auto">
          <span className="fw-bold text-body">Total Events</span>
          <span className="amount">{totalevent}</span>
          <span className="sub-title">
            {totalevent === 0 ? (
              <span className="change down text-danger">
                <Icon name="arrow-long-up" />0 since last month
              </span>
            ) : (
              <span className="change up text-success">
                <Icon name="arrow-long-up" />{totalevent} since last month
              </span>
            )}
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold text-body">Completed Events</span>
          <span className="amount">{successfulevent}</span>
          <span className="sub-title">
            {successfulevent === 0 ? (
              <span className="change down text-danger">
                <Icon name="arrow-long-down" />0 since last month
              </span>
            ) : (
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                {successfulevent} since last month
              </span>
            )}
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold text-body">Incomplete Events</span>
          <span className="amount">{incomplete_Events}</span>
          <span className="sub-title">
            <span className="change down text-danger">
              <Icon name="arrow-long-down" />
              {incomplete_Events} since last month
            </span>
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold text-body">Pending Events</span>
          <span className="amount">{pending_event}</span>
          <span className="sub-title">
            <span className="change down text-danger">
              <Icon name="arrow-long-down" />
              {pending_event} since last month
            </span>
          </span>
        </div>
      </div>
      <div className="mx-3 mt-5">
        <Progress
          className="progress-lg text-body progress"
          striped
          value={finalPorogressVal === "NaN" ? "0.00" : finalPorogressVal}
        ></Progress>
        <div className="fw-bold text-center mt-5 mb-2 text-body">Total Events Completed Ratio</div>
      </div>
    </React.Fragment>
  );
};
export default TotalEvent;
