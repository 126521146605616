import React, { useState } from "react";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getCookie } from "../../utils/Utils";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const AddNewOpetionModal = ({ isOpen, toggle, label, api, id }) => {
  const [eventName, setEventName] = useState("");
  const [errors, setErrors] = useState({ eventName: "", eventValue: "" });
  // let token = process.env.REACT_APP_AUTH_TOKEN;
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    // Perform validation before submitting
    if (!eventName.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, eventName: `${label} field is required` }));
      return;
    }

    // If validation passes, proceed with submission
    try {
      const formData = new FormData();
      formData.append("name", eventName);
      formData.append("type", id);

      const response = await fetch(`${api}`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      } else {
        const responseData = await response.json();
        queryClient.invalidateQueries({ queryKey: ["get-event-type"] });
        queryClient.invalidateQueries({ queryKey: ["get-new-action"] });
        queryClient.invalidateQueries({ queryKey: ["get-attire"] });
        toast.success("Added successfully!");
      }

      setEventName("");
      toggle();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>{`Add new ${label}`}</ModalHeader>
      <ModalBody>
        <div>
          {/* Label Input */}
          <div>
            <Label for="eventName">
              {label} Name <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              id="eventName"
              name="eventName"
              value={eventName}
              onChange={(e) => {
                setEventName(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, eventName: "" }));
              }}
              placeholder={`Enter ${label}`}
            />
            {errors.eventName && <span className="text-danger">{errors.eventName}</span>}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="bg-light py-1 px-1">
        <Button type="button" color="primary" onClick={handleSubmit}>
          <em className="icon ni ni-save" />
          <span>Save</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewOpetionModal;
