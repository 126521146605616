import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import DatePicker from "react-datepicker";
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Label,
  FormGroup,
  Input,
  Form,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row, RSelect } from "../../../../src/components/Component";
import { setDateForPicker } from "../../../utils/Utils";
import { eventOptions, returnDate } from "./CalenderData";
import { useNavigate } from "react-router";
import ReactSelect from "react-select";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { addSlotavailability } from "../../../http/post/postApi";
import { getAvailableSlot, getEventsList } from "../../../http/get/getApi";
import Swal from "sweetalert2";
import { deleteAvailableTimeSlot } from "../../../http/delete/deleteApi";
import { useTheme } from "../../../layout/provider/Theme";


const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{extendedProps.description}</PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

const CalenderApp = ({ events1, events, onDelete, onEdit, setModal1, styles }) => {
  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(events1);
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState();
  const [edit, updateEditModal] = useState(false);
  const [eventid, setEventId] = useState();
  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });

  const naviaget = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    updateEvents(events);
  }, [events]);


  const { skin } = useTheme();

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid #384d69",
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      border: "1px solid #384d69",
    }),
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (formData) => {
    let newEvent = {};
    newEvent = {
      id: event.id,
      className: theme.value,
      type: theme,
      title: formData.title,
      start: event.start,
      end: event.end,
      description: formData.description,
    };
    onEdit(newEvent);
    settheme("");
    toggleEdit();
  };

  const toggle = () => {
    updateModal(!modalState);
  };

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  // const handleEventClick = (info) => {
  //   console.log("testing click", info);
  //   const event = events.find((item) => item.id === info.event._def.publicId);
  //   updateEvent(event);
  //   settheme(event.type);
  //   toggle();
  // };

  const {
    mutate: deleteavlSlot,
    isLoading: isavailbaletimeslotDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-avlaibleSlot-by-id"],
    mutationFn: (data) => deleteAvailableTimeSlot(data),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries("get-available-slot");
      }
    },
  });

  function getEventIdNumeric(eventId) {
    const parts = eventId?.split("-");
    return parts.pop();
  }

  function handleEventClick(info) {
    const eventId = info.event.id;
    const numericId = getEventIdNumeric(eventId);
    setEventId(numericId);
    deletetoggleModal();
  }

  const handleDelete = (eventid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteavlSlot(eventid);
      }
      deletetoggleModal();
    });
  };

  useEffect(() => {
    reset(event);
  }, [event]);

  function editEvent() {
    naviaget(`add-event?type=edit&id=${10}&from=fromEdit`);
    setModal1(false);
  }
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const toggleModal = () => setModal(!modal);
  const deletetoggleModal = () => setDeleteModal(!deleteModal);
  const [formData, setFormData] = useState({
    startdate: "",
    enddate: "",
    From: "",
    to: "",
    occur: "",
    interval: "",
    byweekday: "",
    bymonthday: "",
    bymonth: ",",
  });

  // console.log("formData", formData);

  const handleDateClick = (info) => {
    setSelectedDate(info.dateStr);
    setFormData({ startdate: info.dateStr });
    const selectedDate1 = new Date(info.date);
    const clickedDay = selectedDate1.toLocaleDateString("en-US", { weekday: "short" });
    const day = clickedDay.toLowerCase().slice(0, 2);

    setFormData((prevState) => ({
      ...prevState,
      enddate: info.dateStr,
    }));

    setFormData((prevState) => ({
      ...prevState,
      byweekday: day,
    }));
    const monthday = selectedDate1.getDate();
    setFormData((prevState) => ({
      ...prevState,
      bymonthday: monthday,
    }));
    const month = selectedDate1.getMonth() + 1;
    setFormData((prevState) => ({
      ...prevState,
      bymonth: month,
    }));

    toggleModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "startdate") {
      const selectedDate1 = new Date(value);
      const clickedDay = selectedDate1.toLocaleDateString("en-US", { weekday: "short" });
      const day = clickedDay.toLowerCase().slice(0, 2);
      setFormData((prevState) => ({
        ...prevState,
        byweekday: day,
      }));
      const monthday = selectedDate1.getDate();
      setFormData((prevState) => ({
        ...prevState,
        bymonthday: monthday,
      }));
      const month = selectedDate1.getMonth() + 1;
      setFormData((prevState) => ({
        ...prevState,
        bymonth: month,
      }));

    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleOccurChange = (selectedOption) => {
    setFormData((prevFormDate) => ({
      ...prevFormDate,
      occur: selectedOption.value,
    }));
  };
  const handleInervalChange = (selectedOption) => {
    setFormData((prevFormDate) => ({
      ...prevFormDate,
      interval: selectedOption.value,
    }));
  };

  const options = [
    { value: "DAILY", label: "DAILY" },
    { value: "WEEKLY", label: "WEEKLY" },
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "YEARLY", label: "YEARLY" },
  ];

  const interval = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const {
    data: newformateventData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["get-events"],
    queryFn: () => getEventsList({ id: "", page: "", limit: "", search: "" }),
    staleTime: 5000,
    cacheTime: 300000,
    retry: 1,
  });

  const eventsData = Array.isArray(newformateventData?.data)
    ? newformateventData.data
        .map((event) => {
          if (!event || !event.start_date_time || !event.end_date_time || !event.event_name || !event.event_type_name) {
            return null;
          }

          const startDate = new Date(event.start_date_time).toISOString().split("T")[0];
          const endDate = new Date(event.end_date_time).toISOString().split("T")[0];

          return {
            id: "default-event-id-" + event.id,
            title: event.event_name,
            start: startDate,
            end: endDate,
            description: event.event_type_name,
          };
        })
        .filter((event) => event !== null)
    : [];

  // get Available slot
  const {
    data: availableSlots,
    isLoading: availableSlotLoading,
    isError: availableSlotError,
  } = useQuery({
    queryKey: ["get-available-slot"],
    queryFn: () => getAvailableSlot(),
  });
console.log("availableSlots",availableSlots)

  const {
    mutate: availableSlot,
    isLoading: istimeslotadding,
    error: istimesloterror,
  } = useMutation({
    mutationKey: ["add-availabilty-timeslot"],
    mutationFn: (value) => addSlotavailability(value),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries("get-available-slot");
      }
    },
    onError: (error) => {
      toast.error("Failed to update company profile.");
    },
  });

  const handleFormSubmit1 = (e) => {
    e.preventDefault();

    availableSlot(formData);

    toggleModal();
  };

  const data = [
    {
      id: "default-event-id-" + 2,
      title: "Annual Event",
      rrule: {
        freq: "yearly", // yearly recurrence
        interval: 1, // every year
        bymonth: [8], // August (Month 8)
        bymonthday: [15], // 15th day of August
        byweekday: null, // null because it’s not restricted to specific weekdays
        dtstart: "2024-08-15", // start date
        until: null, // end date (optional, if you want to specify an end date)
      },
      allDay: true, // Event spans the whole day
      color: "green", // different color for the annual event
    },
  ];

  // {
  //   id: "default-event-id-" + 1,
  //   title: "Daily Event",
  //   rrule: {
  //     freq: "daily", // daily recurrence
  //     interval: 1, // every day
  //     dtstart: "2024-08-16", // start date
  //     until: "2024-08-29", // end date
  //   },
  //   color: "green",
  // },

  function convertTo12HourFormat(time24) {
    const [hour, minute] = time24.split(":");
    let hourNum = parseInt(hour, 10);
    const ampm = hourNum >= 12 ? "PM" : "AM";
    hourNum = hourNum % 12 || 12;
    return `${hourNum}:${minute.padStart(2, "0")} ${ampm}`;
  }
  
  const rruleFormatData = availableSlots?.data?.map((data) => {
    const startTime12 = convertTo12HourFormat(data.start_time);
    const endTime12 = convertTo12HourFormat(data.end_time);
     const sday = new Date(data.start_date).getDate();
     const eday = new Date(data.end_date).getDate();
     const recurring = data.recurring === null || undefined ? "daily" : data.recurring?.toLowerCase();
  
     return {
       id: "default-event-id-" + data.id,
       title: `${startTime12} to ${endTime12}`,
       rrule: {
         freq: recurring,
         interval: data.interval ? JSON.parse(data.interval) : 1, // default interval if missing
         byweekday:
           recurring === "daily" || recurring === "monthly" || recurring === "yearly"
             ? null
             : [data.byweekday],
         bymonthday:
           recurring === "yearly" || (recurring === "monthly" && sday <= eday)
             ? Array.from({ length: eday - sday + 1 }, (_, i) => i + sday) // Create array from sday to eday
             : data.bymonthday && recurring !== "weekly" && recurring !== "daily"
             ? [parseInt(data.bymonthday, 10)]
             : null,
         bymonth:
           recurring === "yearly"
             ? [parseInt(data.bymonth, 10)]
             : null,
         dtstart: data.start_date,
         until: 
           recurring === "daily"
             ? null
             : data.end_date && data.end_date !== data.start_date
             ? data.end_date
             : "",
       },
       color: "green",
     };
    // return {
    //   id: "default-event-id-" + data.id,
    //   title: `${startTime12} to ${endTime12}`,
    //   rrule: {
    //     freq: data?.recurring === null || undefined ? "daily" : data.recurring?.toLowerCase(),
    //     interval: JSON.parse(data.interval),
    //     byweekday: data.recurring === null || undefined ? "daily" : data.recurring.toLowerCase() === "daily" ? null : data.recurring.toLowerCase() === "monthly" ? null : data.recurring.toLowerCase() === "yearly" ? null : [data.byweekday],
    //     bymonthday: data.recurring === null || undefined ? "daily" : 
    //     data.recurring.toLowerCase() === "yearly" || data.recurring.toLowerCase() === "monthly" && sday <= eday
    //     ? Array.from({ length: eday - sday + 1 }, (_, i) => i + sday) // Creates an array from sday to eday
    //     : data.bymonthday && data.recurring.toLowerCase() !== "weekly" && data.recurring.toLowerCase() !== "daily"
    //     ? [parseInt(data.bymonthday, 10)]
    //     : null,
    //     bymonth: data.recurring === null || undefined ? "daily" : data.recurring.toLowerCase() === "monthly" ? null : data.bymonth && data.recurring.toLowerCase() !== "weekly" && data.recurring.toLowerCase() !== "daily" && data.recurring.toLowerCase() === "yearly" ? [parseInt(data.bymonth, 10)] : null,
    //     dtstart: data.start_date,
    //     until: data.recurring === null || undefined ? "daily" : data.recurring.toLowerCase() === "yearly" ? null : data.recurring.toLowerCase() === "monthly" ? null : data.end_date != data.start_date ? data.end_date : "",
    //   },
    //   color: "green",
    // };
  });

  // console.log("rruleFormatData", rruleFormatData);

  // const totalData = [...mockEvents, ...(rruleFormatData || [])];
  const totalData = [...mockEvents, ...(rruleFormatData || [])];

  return (
    <React.Fragment>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interactionPlugin, rrulePlugin]}
        // events={mockEvents}
        events={totalData}
        // events={data}
        // eventClick={(info) => handleEventClick(info)}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        // initialView="dayGridMonth"
        initialView="customMonth"
        dayMaxEventRows={2}
        headerToolbar={{
          left: "title prev,next",
          center: null,
          right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        themeSystem="bootstrap5"
        height={800}
        contentHeight={780}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={true}
        droppable={true}
        views={{
          customMonth: {
            type: "dayGridMonth",
            fixedWeekCount: false,
          },
        }}
      />

      <Modal isOpen={modalState} toggle={toggle} className="modal-md">
        <ModalHeader className={event && event.className} toggle={toggle}>
          {event && event.title}
        </ModalHeader>
        <ModalBody>
          <Row className="gy-3 py-1">
            <Col sm="6">
              <h6 className="overline-title">Start Time</h6>
              <p id="preview-event-start">{event && returnDate(event.start)}</p>
            </Col>
            <Col sm="6" id="preview-event-end-check">
              <h6 className="overline-title">End Time</h6>
              <p id="preview-event-end">{event && returnDate(event.end)}</p>
            </Col>
            <Col sm="10" id="preview-event-description-check">
              <h6 className="overline-title">Description</h6>
              <p id="preview-event-description">{event && event.description}</p>
            </Col>
          </Row>
          <ul className="d-flex justify-content-between gx-4 mt-3">
            <li>
              {/* <Button
                color="primary"
                // onClick={() => {
                //   toggle();
                //   toggleEdit();
                // }}
                onClick={editEvent}
              >
                Edit Event
              </Button> */}
            </li>
            <li>
              {/* <Button
                color="danger"
                className="btn-dim"
                onClick={() => {
                  toggle();
                  onDelete(event && event.id);
                }}
              >
                Delete
              </Button> */}
            </li>
          </ul>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Set Availability</ModalHeader>
        <Form onSubmit={handleFormSubmit1}>
          <ModalBody>
            <div className="row">
              <FormGroup className="col-md-6">
                <Label for="startdate">Start Date</Label>
                <Input
                  type="date"
                  name="startdate"
                  id="startdate"
                  value={formData.startdate ? formData.startdate : selectedDate}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="enddate">End Date</Label>
                <Input
                  type="date"
                  name="enddate"
                  id="enddate"
                  value={formData.enddate ? formData.enddate : selectedDate}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="From">Start Time</Label>
                <Input type="time" name="From" id="From" value={formData.From} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="to">End Time</Label>
                <Input type="time" name="to" id="to" value={formData.to} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="description">Recurring</Label>
                <ReactSelect
                  options={options}
                  id="occur"
                  name="occur"
                  value={formData.occur?.label}
                  onChange={handleOccurChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="interval">Interval</Label>
                <ReactSelect
                  options={interval}
                  id="interval"
                  name="interval"
                  value={formData.interval?.label}
                  onChange={handleInervalChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>{" "}
            <Button color="danger" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* <Modal isOpen={edit} toggle={toggleEdit} className="modal-md">
        <ModalHeader toggle={toggleEdit}>Edit Event</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-4 gy-3">
              <Col size="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="event-title">
                    Event Title
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="event-title"
                      {...register('title', { required: true })}
                      className="form-control"
                      value={event.title} 
                      onChange={(e) => updateEvent({ ...event, title: e.target.value })}/>
                    {errors.title && <p className="invalid">This field is required</p>}
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Start Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.start)}
                          onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date) })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={dates.startTime}
                          onChange={(date) => setDates({ ...dates, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">End Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.end)}
                          onChange={(date) => updateEvent({ ...event, end: setDateForPicker(date) })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={dates.endTime}
                          onChange={(date) => setDates({ ...dates, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="event-description">
                    Event Description
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control"
                      id="event-description"
                      {...register('description', { required: true })}
                      value={event.description}
                      onChange={(e) => updateEvent({ ...event, description: e.target.value })}></textarea>
                    {errors.description && <p className="invalid">This field is required</p>}
                  </div>
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">Event Category</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={eventOptions}
                      defaultValue={event.type}
                      onChange={(e) => settheme(e)}
                      //ref={register({ required: true })}
                    />
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="d-flex justify-content-between gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary">
                      Update Event
                    </Button>
                  </li>
                  <li>
                    <Button color="danger" className="btn-dim" onClick={toggleEdit}>
                      Discard
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal> */}

      <Modal isOpen={deleteModal} toggle={deletetoggleModal}>
        <ModalHeader toggle={deletetoggleModal}>Edit Slot Availability</ModalHeader>
        <Form onSubmit={handleFormSubmit1}>
          <ModalBody>
            <div className="row">
              <FormGroup className="col-md-6">
                <Label for="startdate">Start Date</Label>
                <Input
                  type="date"
                  name="startdate"
                  id="startdate"
                  value={formData.startdate ? formData.startdate : selectedDate}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="enddate">End Date</Label>
                <Input
                  type="date"
                  name="enddate"
                  id="enddate"
                  value={formData.enddate ? formData.enddate : selectedDate}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="From">Start Time</Label>
                <Input type="time" name="From" id="From" value={formData.From} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="to">End Time</Label>
                <Input type="time" name="to" id="to" value={formData.to} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="description">Recurring</Label>
                <ReactSelect
                  options={options}
                  id="occur"
                  name="occur"
                  value={formData.occur?.label}
                  onChange={handleOccurChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="interval">Interval</Label>
                <ReactSelect
                  options={interval}
                  id="interval"
                  name="interval"
                  value={formData.interval?.label}
                  onChange={handleInervalChange}
                  styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
                />
              </FormGroup>
            </div>
          </ModalBody>
        </Form>
        <ModalFooter>
          {/* <Button color="primary">
            Edit
          </Button>{" "} */}
          <Button color="danger" onClick={() => handleDelete(eventid)}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CalenderApp;
