import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const stripePromise = loadStripe(
  "pk_test_51Omr88AcvRDN3zo6aFlC0XaohxcPron6tXuIbPfDVv3w9UIfNTWyaBSMUtsLpb5XDwFUVSsZRsonSwhQISFyuete005HM5M5PW"
);

const StripePaymentForm = ({ price, name }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }

      const stripeToken = require("stripe")(
        "sk_test_51PAALgDNuoMtkTsRWMBWp4RK5U1UUZKpZILDxbhJHvBwWXvOTzq8XGRwu6qRY3lDCHNt823hnTnn5U0AW5Ki68Bu00O94vjoTV"
      );
      const customer = await stripeToken.customers.create({
        name: "Jenny Rosen",
        email: "jennyrosen@example.com",
        source: token.id,
      });

      // const charge = await stripeToken.charges.create({
      //   amount: 1099,
      //   currency: "usd",
      //   source: "tok_visa",
      // });

      // handlePayment(token.id, price, name);
      // toast.success("Plan Purchased Successfully");
    } catch (err) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayment = async (tokenId, price, name) => {
    const note = `${name} plan purchased`;
    const payerId = getCookie("user_id");

    console.log(price, "price", name, "name", tokenId, "token_id");
    try {
      const formData = new FormData();
      formData.append("stripeToken", tokenId);
      formData.append("amount", price);
      formData.append("note", note);
      formData.append("purpose", "1");
      formData.append("payer_id", payerId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/payment/stripe-payment`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Payment failed.");
      }
    } catch (error) {
      setErrorMessage(error.message || "Something went wrong!");
    }
  };

  const validateToast = () => {
    toast.info("Coming Soon!");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                color: "#666",
                fontSize: "20px",
              },
              invalid: {
                color: "#fa755a",
                fontSize: "20px",
              },
            },
          }}
        />
        <div className="justify-center">
          {/* <button type="submit" disabled={isLoading} className="btn btn-primary">
          {isLoading ? "Processing..." : "Pay Now"}
        </button> */}
        </div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </form>
      <div className="justify-center">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => toast.info("Coming Soon!")}
          disabled={isLoading}
        >
          Pay Now
        </button>
      </div>
    </>
  );
};

const StripePayment = ({ price, name }) => (
  <Elements stripe={stripePromise}>
    <StripePaymentForm price={price} name={name} />
  </Elements>
);

export default StripePayment;
