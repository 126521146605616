import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import menu from "../menu/MenuData";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { UserAvatar, LinkList, LinkItem, Icon, TooltipComponent } from "../../components/Component";
import { useTheme } from "../provider/Theme";
import Cookies from "js-cookie";
import LogoFetch from "../../pages/prebuilt/Logo Separation/LogoFetch";
const Appbar = () => {
  const theme = useTheme();

  let currentUrl;
  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const appSidebarClass = classNames({
    "nk-apps-sidebar": true,
    [`is-light`]: theme.appbar === "white",
    [`is-${theme.appbar}`]: theme.appbar !== "white" && theme.appbar !== "light",
  });

  return (
    <div className={appSidebarClass}>
      <LogoFetch />
      <div className="nk-sidebar-element" >
        <div className="nk-sidebar-body" >
          <SimpleBar className="nk-sidebar-content ">
            <div className="nk-sidebar-menu">
              <ul className="nk-menu apps-menu">
                {menu.map((item, index) => {
                  if (item.dashboard) {
                    return (
                      <React.Fragment key={index}>
                        <TooltipComponent id={"dashboard" + index} text={item.text} direction="right" />
                        <li
                          className={`nk-menu-item ${
                            currentUrl === process.env.PUBLIC_URL + item.link
                              ? "active current-page border-start border-3"
                              : ""
                          }`}
                          key={index}
                          id={"dashboard" + index}
                        >
                          <Link to={`${process.env.PUBLIC_URL + item.link}`} className="nk-menu-link">
                            <span className="nk-menu-icon">
                              <Icon name={item.icon}></Icon>
                            </span>
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  } else if (item.text === "Applications") {
                    return (
                      <React.Fragment key={index}>
                        <li className="nk-menu-hr"></li>
                        {item.subMenu.map((sub, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <TooltipComponent id={"app" + idx} text={sub.text} direction="right" />
                              <li
                                className={`nk-menu-item ${
                                  currentUrl === process.env.PUBLIC_URL + sub.link
                                    ? "active current-page border-start border-3"
                                    : ""
                                }`}
                                key={idx}
                                id={"app" + idx}
                              >
                                <Link to={`${process.env.PUBLIC_URL + sub.link}`} className="nk-menu-link">
                                  <span className="nk-menu-icon">
                                    <Icon name={sub.icon}></Icon>
                                  </span>
                                </Link>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  } else return <React.Fragment key={index}></React.Fragment>;
                })}

                {/* Add event */}
                <TooltipComponent id={"eventTooltip"} text="Add event" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/add-event")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="eventTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/add-event`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="calendar-alt"></Icon>
                    </span>
                  </Link>
                </li>

                {/* Event list */}
                <TooltipComponent id={"eventlistTooltip"} text="Event list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    currentUrl === process.env.PUBLIC_URL + "/event-list"
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="eventlistTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/event-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="icon ni ni-list-thumb-alt"></Icon>
                    </span>
                  </Link>
                </li>

                {/* Clients */}
                <TooltipComponent id={"clientTooltip"} text="Client list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/client-list" || currentUrl === process.env.PUBLIC_URL + "/client-list/create-client")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="clientTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/client-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="user-c"></Icon>
                    </span>
                  </Link>
                </li>
                {/* Employee */}
                <TooltipComponent id={"employeesTooltip"} text="Employee list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/employee-list" || currentUrl === process.env.PUBLIC_URL + "/employee-list/create-employee")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="employeesTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/employee-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="icon ni ni-users"></Icon>
                    </span>
                  </Link>
                </li>
                {/* Venue */}
                <TooltipComponent id={"VenueTooltip"} text="Venue list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/venue-list" || currentUrl === process.env.PUBLIC_URL + "/venue-list/create-venue")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="VenueTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/venue-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="icon ni ni-location"></Icon>
                    </span>
                  </Link>
                </li>
                {/* vender */}
                <TooltipComponent id={"venderTooltip"} text="Vendor list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/vendor-list" || currentUrl === process.env.PUBLIC_URL + "/vendor-list/create-vender")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="venderTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/vendor-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="icon ni ni-home-alt"></Icon>
                    </span>
                  </Link>
                </li>
                {/* package */}
                <TooltipComponent id={"packageTooltip"} text="Package list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/package-list" || currentUrl === process.env.PUBLIC_URL + "/package-list/create-package")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="packageTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/package-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="package"></Icon>
                    </span>
                  </Link>
                </li>

                {/* Plan UI  */}
                <TooltipComponent id={"planTooltip"} text="Plan" direction="right" />
                {Cookies.get("role") === "1" && (
                  <li
                    className={`nk-menu-item ${
                      currentUrl === process.env.PUBLIC_URL + "/project-card"
                        ? "active current-page border-start border-3"
                        : ""
                    }`}
                    id="planTooltip"
                  >
                    <Link to={`${process.env.PUBLIC_URL}/project-card`} className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <Icon name="clipboad-check"></Icon>
                      </span>
                    </Link>
                  </li>
                )}

                {/* services */}
                <TooltipComponent id={"servicesTooltip"} text="Profile" direction="right" />
                <li
                  className={`nk-menu-item ${
                    (currentUrl === process.env.PUBLIC_URL + "/Company-profile" || currentUrl === process.env.PUBLIC_URL + "/Company-profile/edit-profile")
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="servicesTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/Company-profile`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="user-circle"></Icon>
                    </span>
                  </Link>
                </li>

                {/* Enquiry List */}
                <TooltipComponent id={"enquiryTooltip"} text="Inquiry list" direction="right" />
                <li
                  className={`nk-menu-item ${
                    currentUrl === process.env.PUBLIC_URL + "/inquiry-list"
                      ? "active current-page border-start border-3"
                      : ""
                  }`}
                  id="enquiryTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/inquiry-list`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="user-list"></Icon>
                    </span>
                  </Link>
                </li>


                {/* Logo UI  */}
                {/* <TooltipComponent id={"logoTooltip"} text="Site Setting" direction="right" />
                {Cookies.get("role") === "1" && (
                  <li
                    className={`nk-menu-item ${
                      currentUrl === process.env.PUBLIC_URL + "/site-setting" ? "active current-page" : ""
                    }`}
                    id="logoTooltip"
                  >
                    <Link to={`${process.env.PUBLIC_URL}/site-setting`} className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <Icon name="setting-fill"></Icon>
                      </span>
                    </Link>
                  </li>
                )} */}

                {/* Company_list UI  */}
                {/* <TooltipComponent id={"listTooltip"} text="Company List" direction="right" />
                {Cookies.get("role") === "1" && (
                  <li
                    className={`nk-menu-item ${
                      currentUrl === process.env.PUBLIC_URL + "/company-list" ? "active current-page" : ""
                    }`}
                    id="listTooltip"
                  >
                    <Link to={`${process.env.PUBLIC_URL}/company-list`} className="nk-menu-link">
                      <span className="nk-menu-icon">
                        <Icon name="property-alt"></Icon>
                      </span>
                    </Link>
                  </li>
                )} */}

                {/*  Plan Purchase */}
                 <TooltipComponent id={"subscriptionTooltip"} text="Select plan" direction="right" />
                <li
                  className={`nk-menu-item ${
                    currentUrl === process.env.PUBLIC_URL + "/plan-selection" ? "active current-page border-start border-3" : ""
                  }`}
                  id="subscriptionTooltip"
                >
                  <Link to={`${process.env.PUBLIC_URL}/plan-selection`} className="nk-menu-link">
                    <span className="nk-menu-icon">
                      <Icon name="contact"></Icon>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
