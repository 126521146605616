import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import NavigationFooter from "../components/NavigationFooter";
import UploadMultipleImages from "./UploadMultipleImages";
import Swal from "sweetalert2";
import { getCookie } from "../../utils/Utils";
import { DataContext } from "../../App";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteImages } from "../../http/delete/deleteApi";
import { getCompanyProfile } from "../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Link, useNavigate } from "react-router-dom";
import subscribeimg from "../../../src/assets/images/subscribe.jpg";
import { useTheme } from "../../layout/provider/Theme";
import Select from 'react-select';

const Allcategories = [
  { value: "Wedding", label: "Wedding" },
  { value: "Parties", label: "Parties" },
  { value: "Birthday Party", label: "Birthday Party" },
  { value: "Corporate Events", label: "Corporate Events" },
  { value: "School Events", label: "School Events" },
  { value: "Quinceanera", label: "Quinceanera" },
  { value: "Bar Mitzvah", label: "Bar Mitzvah" },
  { value: "Bat Mitzvah", label: "Bat Mitzvah" },
  { value: "Sweet Sixteen", label: "Sweet Sixteen" },
  { value: "Fashion Show", label: "Fashion Show" },
  { value: "Talent Show", label: "Talent Show" },
  { value: "Grand Opening Event", label: "Grand Opening Event" },
  { value: "Non-Profit Event", label: "Non-Profit Event" },
  { value: "Holiday Event", label: "Holiday Event" },
  { value: "Private Events", label: "Private Events" },
  // { value: "Other", label: "Other" },
];

// let token = process.env.REACT_APP_AUTH_TOKEN;

const Gallery = ({
  currentState,
  formik,
  toggle,
  isCompanyProfileEditing,
  photosLimit,
  imagecount,
  subscribedpalresponce,
  styles,
}) => {
  const { hasUnsavedChanges, setHasUnsavedChanges, planEnddates, activeTab, setActivetab } = useContext(DataContext);
  const categories = Object.keys(formik?.values?.images || "");
  const queryClient = useQueryClient();

  // const [selectedCategory, setSelectedCategory] = useState(Allcategories[0]);
  const [selectedCategory, setSelectedCategory] = useState("Wedding");
  const [previewImages, setPreviewImages] = useState([]);

  const [draggedImage, setDraggedImage] = useState(null);
  const totlaCount = previewImages.length + imagecount;
  const navigate = useNavigate();

  const { skin } = useTheme();

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid #384d69",
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      border: "1px solid #384d69",
    }),
  };

  // const {
  //   data: companyDetails,
  //   isLoading: companyprofileLoading,
  //   isError: companyprofileError,
  // } = useQuery({
  //   queryKey: ["get-company-profile"],
  //   queryFn: () => getCompanyProfile(),
  // });

  const handleValidation = (callbackFun) => {
    // callbackFun(true);

    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== "" 
    ) {
      callbackFun(true);
    }else{
      callbackFun(false);
    }
  };

  const handleAddImage = (base64Strings) => {
    setPreviewImages((prev) => [...prev, ...base64Strings]);
  };

  const handleRemovePreviewImage = (index) => {
    const updatedImages = previewImages.filter((_, i) => i !== index);
    setPreviewImages(updatedImages);
    setHasUnsavedChanges(false);
  };

  const handleSavePreviewImages = () => {
    const currentDate = new Date(); // Get the current date
    const planEndDate = new Date(planEnddates); // Convert planEnddates to a Date object

    // if (previewImages?.length > 0 && !(totlaCount <= photosLimit)) {
    if (!subscribedpalresponce) {
      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add images to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection");
        }
      });
    } else if (planEndDate < currentDate) {
      Swal.fire({
        title: "Subscription Expired",
        text: "Your subscription has expired. Please renew your subscription to proceed.",
        icon: "warning",
        confirmButtonText: "Renew Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/plan-selection"); // Navigate to the plan selection page
        }
      });
    } else {
      if (totlaCount <= photosLimit) {

        handleValidation((validation) => {
          if(validation === false){
            setActivetab("1")
            navigate("/Company-profile/edit-profile")
          }
          else if (validation === true) {
          
            const updatedImages = {
              ...formik?.values?.images,
              [selectedCategory]: [...(formik?.values?.images[selectedCategory] || []), ...previewImages],
            };
            formik.setFieldValue("images", updatedImages);
            formik.submitForm();
            setPreviewImages([]);
          }
        })
      } else {
        Swal.fire({
          title: "Images Limit Exceeded!!",
          text: `You've reached the limit of ${photosLimit} for your images. Upgrade your plan to add more images and make your business stand out!`,
          icon: "warning",
          confirmButtonText: "Upgrade Plan Now!",
          cancelButtonText: "Maybe Later",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/plan-selection");
            setActivetab("5");
          }
        });
      }
    }
  };


  const {
    mutate: deleteimage,
    isLoading: isimageDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-img-by-id-category"],
    mutationFn: (data) => deleteImages(data),
    onSuccess: (data) => {
      if (data.status) {
        queryClient.invalidateQueries("get-company-profile");
      }
    },
  });
  const deletImage = async (id, category) => {
    const imgdata = { id, category };
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteimage(imgdata);
          // eventsList.data = eventsList.data.filter((event) => event?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: "Your image has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } catch (error) {
      console.error("Error deleting mixe:", error);
    }
  };

  const handleDragStart = (category, index) => {
    setDraggedImage({ category, index });
  };

  const handleDrop = (e, targetCategory) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedImage && draggedImage.category !== targetCategory) {
      const { category, index } = draggedImage;
      const imageToMove = formik.values.images[category][index];

      // Remove the image from the original category
      const updatedSourceImages = {
        ...formik.values.images,
        [category]: formik.values.images[category].filter((_, i) => i !== index),
      };
      if (updatedSourceImages[category].length === 0) {
        delete updatedSourceImages[category];
      }

      // Add the image to the target category
      const updatedTargetImages = {
        ...updatedSourceImages,
        [targetCategory]: [...(updatedSourceImages[targetCategory] || []), imageToMove],
      };

      formik.setFieldValue("images", updatedTargetImages);
      setDraggedImage(null);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Row className="p-2 py-0">
      <Col md={4} className="border p-3 bg-white">
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="categorySelect">Select Category</Label>
              <Select
                id="categorySelect"
                name="categorySelect"
                value={Allcategories?.find((option) => option?.value === selectedCategory)}
                onChange={(selectedOption) => setSelectedCategory(selectedOption?.value)}
                options={Allcategories}
                styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
              />
            </FormGroup>
            <UploadMultipleImages
              label="Upload Image"
              name="files"
              handleAddImage={handleAddImage}
              isCompanyProfileEditing={isCompanyProfileEditing}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            <div className="row">
              {previewImages.map((image, index) => (
                <Col key={index} md={3} className="position-relative mb-3">
                  <img
                    src={image}
                    alt={`preview ${index}`}
                    className="img-fluid"
                    style={{
                      height: "100px", // Adjust height as needed
                      width: "auto", // Auto width to maintain aspect ratio
                      objectFit: "cover", // Or use 'contain' as per your design
                      border: "1px solid #EBECEC",
                    }}
                  />
                  <Button
                    color="danger"
                    size="xs"
                    className="position-absolute"
                    style={{ top: "0px", right: "14px" }}
                    onClick={() => handleRemovePreviewImage(index)}
                  >
                    X
                  </Button>
                </Col>
              ))}
            </div>
          </div>
        </Row>
        {/* {previewImages?.length > 0 && totlaCount <= photosLimit && ( */}
        {previewImages.length > 0 ? (
          <Button
            type="button"
            color="primary"
            onClick={handleSavePreviewImages}
            className="btn btn-success mt-2"
            // style={{marginLeft:'85%'}}
          >
            Upload
          </Button>
        ) : (
          ""
        )}
        {/* )} */}
        {/* {!(totlaCount <= photosLimit) ?  (
          !subscribedpalresponce ? (
            <span style={{ color: "red" }}>
              <Link to="/plan-selection" style={{ color: "red",  cursor: 'pointer',}}>
                Please subscribe plan!
              </Link>
            </span>
          ) : (
            <span style={{ color: "red" }}>You have reached your Mixes limit. Please upgrade your plan !</span>
          )
        ) : (
          ""
        )} */}
      </Col>

      <Col md={8} className="border p-3 bg-white border-start-0">
        {isCompanyProfileEditing ? (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            {categories.map(
              (category) =>
                formik.values.images[category] &&
                formik.values.images[category].length > 0 && (
                  <div
                    key={category}
                    onDrop={(e) => handleDrop(e, category)}
                    onDragOver={handleDragOver}
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <h5>{category}</h5>
                    <Row>
                      {formik.values.images[category].map((image, index) => (
                        <Col
                          key={index}
                          md={2} // Adjusted to fit 6 columns per row
                          className="position-relative mb-1"
                        >
                          <div className="position-relative" style={{ width: "100px", height: "70px" }}>
                            <ShimmerThumbnail height={70} width={100} rounded />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
            )}
          </div>
        ) : (
          <div style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
            {categories.map(
              (category) =>
                formik.values.images[category] &&
                formik.values.images[category].length > 0 && (
                  <div
                    key={category}
                    onDrop={(e) => handleDrop(e, category)}
                    onDragOver={handleDragOver}
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <h5>{category}</h5>
                    <Row>
                      {formik.values.images[category].map((image, index) => (
                        <Col
                          key={index}
                          md={2} // Adjusted to fit 6 columns per row
                          className="position-relative mb-1"
                          draggable
                          onDragStart={() => handleDragStart(category, index)}
                        >
                          <div className="position-relative" style={{ width: "100px", height: "70px" }}>
                            <img
                              src={image.url} // Ensure you access the 'path' property here
                              alt={`uploaded ${index}`}
                              className="img-fluid"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                border: "1px solid #EBECEC",
                              }}
                            />
                            <Button
                              color="danger"
                              disabled={isCompanyProfileEditing}
                              size="xs"
                              className="position-absolute"
                              style={{ top: "0px", right: "0px" }}
                              onClick={() => deletImage(image.id, category)}
                            >
                              X
                            </Button>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
            )}
          </div>
        )}
      </Col>
      <NavigationFooter
        toggle={toggle}
        currentState={currentState}
        lastPage="9"
        validationFunction={handleValidation}
        formik={formik}
        isCompanyProfileEditing={isCompanyProfileEditing}
        isimageDeleting={isimageDeleting}
        form={"updateprofile"}
      />
    </Row>
  );
};

export default Gallery;
