import { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import { Icon } from "../../components/Component";

const TableActionBar = ({
  options1Icon,
  options1TooleTipText,
  options1onClick,
  options2Icon,
  options2TooleTipText,
  options2onClick,
  options3Icon,
  options3TooleTipText,
  options3onClick,
  options4Icon,
  options4TooleTipText,
  options4onClick,
  isEventAdded,
  isPaymentMethodDefined,
  isAllPaymentDone,
  isEventDone,
}) => {
  const [id, setId] = useState(generateRandomId);
  const [id1, setId1] = useState(generateRandomId);
  const [id2, setId2] = useState(generateRandomId);
  const [id3, setId3] = useState(generateRandomId);

  const [options1IconTooltipOpen, setoptions1IconTooltipOpen] = useState(false);
  const [options2IconTooltipOpen, setoptions2IconTooltipOpen] = useState(false);
  const [options3IconTooltipOpen, setoptions3IconTooltipOpen] = useState(false);
  const [options4IconTooltipOpen, setoptions4IconTooltipOpen] = useState(false);

  const options1IconTooltipToggle = () => {
    setoptions1IconTooltipOpen(!options1IconTooltipOpen);
  };

  const options2IconTooltipToggle = () => {
    setoptions2IconTooltipOpen(!options2IconTooltipOpen);
  };

  const options3IconTooltipToggle = () => {
    setoptions3IconTooltipOpen(!options3IconTooltipOpen);
  };

  const options4IconTooltipToggle = () => {
    setoptions4IconTooltipOpen(!options4IconTooltipOpen);
  };

  function generateRandomId(length = 4) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <div className="d-flex gap-3">
        <div id={id} style={{ cursor: "pointer" }} onClick={options1onClick}>
          <Icon
            name={options1Icon}
            className={`fs-4 ${
              isEventAdded ? (isPaymentMethodDefined || !isEventDone ? "text-success" : "text-danger") : ""
            }`}
          ></Icon>
          <Tooltip placement="top" isOpen={options1IconTooltipOpen} target={id} toggle={options1IconTooltipToggle}>
            {options1TooleTipText}
          </Tooltip>
        </div>

        <div id={id1} style={{ cursor: "pointer" }} onClick={options2onClick}>
          <Icon
            name={options2Icon}
            className={`fs-4 ${
              isPaymentMethodDefined ? (!isAllPaymentDone && isEventDone ? "text-danger" : "text-success") : ""
            }`}
          ></Icon>
          <Tooltip placement="top" isOpen={options2IconTooltipOpen} target={id1} toggle={options2IconTooltipToggle}>
            {options2TooleTipText}
          </Tooltip>
        </div>

        <div id={id2} style={{ cursor: "pointer" }} onClick={options3onClick}>
          <Icon name={options3Icon} className={`fs-4 ${isAllPaymentDone ? "text-success" : ""}`}></Icon>
          <Tooltip placement="top" isOpen={options3IconTooltipOpen} target={id2} toggle={options3IconTooltipToggle}>
            {options3TooleTipText}
          </Tooltip>
        </div>

        <div id={id3} style={{ cursor: "pointer" }} onClick={options4onClick}>
          <Icon name={options4Icon} className={`fs-4 ${isAllPaymentDone && isEventDone ? "text-success" : ""}`}></Icon>
          <Tooltip placement="top" isOpen={options4IconTooltipOpen} target={id3} toggle={options4IconTooltipToggle}>
            {options4TooleTipText}
          </Tooltip>
        </div>
      </div>
    </>
  );
};
export default TableActionBar;
