import React, { useEffect, useState } from "react";
import { Col, Row, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { BlockBetween, BlockContent, BlockDes, BlockTitle, Icon } from "../../../../src/components/Component";
import PlanRegistration from "../../../../src/pages/prebuilt/Registration Plans/PlanRegistration";
import { fetchPlanData } from "../../../http/get/getApi";
import { useQuery } from "react-query";
import Aos from "aos";
import { getCookie } from "../../../utils/Utils";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const MonthlyandYearlyPlan = ({ isCompanyProfileEditing, toggle, currentState }) => {
  const [activeTab, setActiveTab] = useState("1");

  const [data, setData] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [durationType, setDurationType] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalTab, setModalTab] = useState(false);
  //   const [activeTab, setActiveTab] = useState("1");
  const [planName, setPlanName] = useState(null);
  //   const navigate = useNavigate();
  //   const queryClient = useQueryClient();
  const [planEndDate, setPlanEndDate] = useState(null);
  const [freePlan, setFreePlan] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-subscription-plans`, {
  //         method: "POST",
  //       });
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const responseData = await response.json();
  //       console.log("responseData",responseData.data)
  //       setData(responseData?.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //     }
  //   };

  const subscribedPlanAPI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
      });

      if (response.ok) {
        const responseData = await response.json();

        setDurationType(responseData?.data?.package?.plan_data?.duration_type);

        if (durationType === "m") {
          setActiveTab("1");
        } else if (durationType === "y") {
          setActiveTab("2");
        }
        setDataId(responseData?.data?.package?.plan_data?.id);
      } else {
        throw new Error("Failed to fetch plan data");
      }
      return response;
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
  };

  useEffect(() => {
    subscribedPlanAPI();
  }, [durationType]);

  //   const {
  //     data: fetchedPlanData,
  //     isLoading: planDataLoading,
  //     isError: planDataError,
  //   } = useQuery({
  //     queryKey: ["get-planData"],
  //     queryFn: () => fetchPlanData(),
  //   });

  //   useEffect(() => {
  //     if (fetchedPlanData?.status === true) {
  //       fetchData();
  //       subscribedPlanAPI();
  //     } else {
  //       fetchData();
  //     }
  //     Aos.init({ duration: 1000 });
  //     return () => {};
  //   }, []);

  return (
    <div className="p-3">
      <BlockBetween className="g-3">
        <BlockContent>
          <BlockTitle>Select Plan</BlockTitle>
          <BlockDes className="text-soft">
            <p>Choose the perfect plan that fits your needs and budget.</p>
          </BlockDes>
        </BlockContent>
      </BlockBetween>
      <Nav tabs className="mt-2">
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "1" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("1");
            }}
          >
            <Icon name="monthlylan" /> <span>Monthly </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeTab === "2" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleTab("2");
            }}
          >
            <Icon name="yearlyplan" /> <span>Yearly </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PlanRegistration
            duration_type={"m"}
            currentState={activeTab}
            isCompanyProfileEditing={isCompanyProfileEditing}
          />
        </TabPane>
        <TabPane tabId="2">
          <PlanRegistration
            duration_type={"y"}
            currentState={activeTab}
            isCompanyProfileEditing={isCompanyProfileEditing}
          />
        </TabPane>
      </TabContent>

      <div className="mt-4">
        {/* <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage="5"
          validationFunction={(callbackFun) => callbackFun(true)}
          isCompanyProfileEditing={isCompanyProfileEditing}
        /> */}
      </div>
    </div>
  );
};

export default MonthlyandYearlyPlan;
