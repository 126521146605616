import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { ReactDataTable } from "../../components/Component";
import { getClientsList } from "../../http/get/getApi";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { ShimmerTable } from "react-shimmer-effects";
import { deleteClient } from "../../http/delete/deleteApi";
import Swal from "sweetalert2";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const ClientListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");

  const {
    data: clientsList,
    isLoading: clientsListIsLoading,
    isError: clientsListsError,
  } = useQuery({
    queryKey: ["get-clients-list", pageNo, recordLimit, search],
    queryFn: () => getClientsList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    staleTime: 5000,
    cacheTime: 300000, 
    retry: 1,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["get-clients-list"]);
    // },
  });

  // useMutation to delete client
  const {
    mutate: deleteClientMutation,
    isLoading: isDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-client-by-Id"],
    mutationFn: (ID) => deleteClient({ id: ID }),
    onSuccess: (data) => {
      if (data.status && !hasInvalidated) {
        queryClient.invalidateQueries(["get-clients-list"]);

        setHasInvalidated(true);
      }
    },
  });
  const deleteRecord = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteClientMutation(value);
          clientsList.data = clientsList?.data?.clients?.filter((client) => client?.id !== value);
            Swal.fire({
            title :"Deleted!",
            text : "Your client has been deleted.",
             icon : "success",
             showConfirmButton: false,
             timer: 2000,
            });
        }
      });
    } catch (error) {
      console.error("Error deleting vender:", error);
    }
  };

  const isLoading = isDeleting || clientsListIsLoading;

  const tableHeaddings = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      maxWidth: "5px",
    },
    {
      // name: "Client Name",
      name: "UserName",
      // selector: (row) => `${row.first_name} ${row.last_name}` || <NotAvailable />,
      selector: (row) => row.username || <NotAvailable />,
    },

    {
      name: "Contact No.",
      selector: (row) => row.work_phone_number || <NotAvailable />,
    },
    {
      name: "Email",
      selector: (row) => row.email || <NotAvailable />,
    },

    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="eye"
            options1TooleTipText="view"
            options2Icon="icon ni ni-edit"
            options2TooleTipText="Edit"
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            options1onClick={() => navigate(`/client-list/create-client?type=view&id=${row.id}&from=fromView`)}
            options2onClick={() => navigate(`/client-list/create-client?type=edit&id=${row.id}&from=fromEdit`)}
            options3onClick={() => deleteRecord(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationHeader
        heading={"Client List"}
        buttonInnerText={"Add Client"}
        route={"/client-list/create-client?page=client"}
      />
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          data={clientsList?.data?.clients || []}
          columns={tableHeaddings}
          pagination
          actions
          // searchBy={"first_name"}
          searchBy={"username"}
          pageNo={setPageNo} // sending function to change page no
          activePage={clientsList?.data?.page || 0} // sending the current page no
          totalRecords={clientsList?.data?.totalRecords || 10} // sending the total no of records om database
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
    </>
  );
};

export default ClientListPage;
