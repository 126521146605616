import { useState } from "react";
import { Bar } from "react-chartjs-2";

export const monthRevenue = (eventData) => {

  if (!eventData || !eventData.labels || !eventData.datasets[0].data || eventData.datasets.length === 0) {
    return null; 
  }

  return {
    labels: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Sales Revenue",
        width: "30",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: [
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
        ],
        data: eventData?.datasets[0]?.data,
      },
    ],
  };
};


export const yearRevenue = (eventData) => {
  if (!eventData || !eventData.labels || !eventData.datasets[0].data || eventData.datasets.length === 0) {
    return null;
  }
  return {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Sales Revenue",
        width: "30",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: [
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
          "rgba(10,120,89,0.2)",
        ],
        data: eventData?.datasets[0]?.data,
      },
    ],
  };
};


export const valuesForThreeMonth = (eventData) => {
  if (!eventData || !eventData.labels || !eventData.datasets[0].data || eventData.datasets.length === 0) {
    return null; 
  }
  return {
    labels: ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Sales Revenue",
        width: "30",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: ["rgba(10,120,89,0.2)", "rgba(10,120,89,0.2)", "rgba(10,120,89,0.2)", "rgba(10,120,89,0.2)"],
        data: eventData?.datasets[0]?.data,
      },
    ],
  };
};
export const EventPriceBar = ({ auOverview, eventData }) => {
  
// console.log("eventData$$",eventData)
  let montdata1 = null;
  let monthdata3 = null;
  let year1 = null;

  if (auOverview === "1 month") {
    montdata1 = eventData;
  } else if (auOverview === "3 months") {
    monthdata3 = eventData;
  } else if (auOverview === "1 year") {
    year1 = eventData;
  }

 
  var Revenue =
    auOverview === "1 month"
      ? monthRevenue(montdata1)
      : auOverview === "3 months"
      ? valuesForThreeMonth(monthdata3)
      : yearRevenue(year1);

  if (!Revenue) {
    return null;
  }
  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      data={Revenue}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: "11px",
            },
            titleColor: "#6783b8",
            titleMarginBottom: 4,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: "10px",
            },
            bodySpacing: 3,
            padding: 8,
            footerMarginTop: 0,
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
};
