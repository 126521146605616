import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Row,
  ProjectCard,
  UserAvatar,
  Col,
  PaginationComponent,
} from "../../../components/Component";
import { features } from "./ProjectData";
import { MakeApiCall, findUpper } from "../../../utils/Utils";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import FormModal from "./FormModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ProjectCardPage = () => {
  const toggleLg = () => setModalLg(!modalLg);
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };
  const [modalLg, setModalLg] = useState(false);
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    add: false,
    edit: false,
  });
  const [editId, setEditedId] = useState();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(6);
  const [paidValues, setPaidValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [planStates, setPlanStates] = useState({});

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("ordername", "id");
      formData.append("ordervalue", "desc");
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-subscription-plans`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.plans);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure to delete the plan?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.<b><h5>Wait for the page refreshing</h5></b>", "success");
          const formData = new FormData();
          formData.append("id", id);
          MakeApiCall(`api/v1/admin/delete-plan`, formData);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          fetchData();
        }
      });
    } catch (error) {
      console.log("Error deleting plan:", error);
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    durationtype: "m",
    price: "",
    employee: 0,
    client: 0,
    is_paid: "1",
    event_limit: "Unlimited",
    status: "1",
    management: "no",
    technical_support: "no",
    website_tool: "no",
    advanced_features: "no",
    enhanced_support: "no",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    duration: "",
    durationtype: "",
    price: "",
    client: 0,
    employee: 0,
    event_limit: "Unlimited",
    is_paid: "1",
    status: "1",
    management: "",
    technical_support: "",
    website_tool: "",
    advanced_features: "",
    enhanced_support: "",
  });
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  // Function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      duration: "",
      durationtype: "m",
      price: "",
      client: 0,
      employee: 0,
      event_limit: "Unlimited",
      is_paid: "1",
      status: "1",
      management: "no",
      technical_support: "no",
      website_tool: "no",
      advanced_features: "no",
      enhanced_support: "no",
    });
  };
  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };
  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };
  const onFormSubmit = async (sData) => {
    setLoading(true);
    const {
      name,
      duration,
      durationtype,
      price,
      is_paid,
      // status,
      client,
      employee,
      event_limit,
      management,
      technical_support,
      website_tool,
      advanced_features,
      enhanced_support,
    } = sData;
    const lowerCaseNames = data.map((plan) => plan.name.toLowerCase());
    const isDuplicate = lowerCaseNames.includes(name.toLowerCase());
    console.log(isDuplicate, name);
    if (isDuplicate) {
      toast.error("Plan name already exists.");
      return;
    }
    // const activePlansCount = data.filter((plan) => plan.status === "1").length;
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("duration", duration);
      formData.append("duration_type", durationtype);
      formData.append("price", price);
      // formData.append("is_paid", is_paid);
      // if (activePlansCount >= 4 && status === "1") {
      //   // Check if the number of active plans is already four
      //   formData.append("status", "0");
      //   toast.success("Active status is converted to inactive, Only allow 4 active status");
      // } else {
      //   formData.append("status", status);
      // }
      formData.append("is_paid", "1");
      formData.append("status", "0");
      formData.append("client", client);
      formData.append("employee", employee);
      formData.append("event_limit", event_limit);
      formData.append("management", management);
      formData.append("technical_support", technical_support);
      formData.append("website_tool", website_tool);
      formData.append("advanced_features", advanced_features);
      formData.append("enhanced_support", enhanced_support);
      const planData = await MakeApiCall(`api/v1/admin/add-plan`, formData);
      if (planData && planData.status) {
        const submittedData = planData.data;
        setData((data) => [submittedData, ...data]);
        toast.success("Plan added successfully");
        fetchData();
      } else {
        setLoading(false);
        console.log("planUser data is invalid or missing required properties");
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
      resetForm();
      setModal({ add: false });
    }
  };
  const onEditSubmit = async (sData) => {
    setLoading(true);
    const {
      id,
      name,
      duration,
      durationtype,
      price,
      // is_paid,
      // status,
      client,
      employee,
      event_limit,
      management,
      technical_support,
      website_tool,
      advanced_features,
      enhanced_support,
    } = sData;
    try {
      const lowerCaseNames = data.map((plan) => plan.name.toLowerCase());
      const isDuplicate = lowerCaseNames.includes(name.toLowerCase());
      if (isDuplicate && !data.some((plan) => plan.id === id && plan.name === name)) {
        toast.error("Plan name already exists");
        return;
      }
      if (event_limit === "") {
        event_limit = "unlimited";
      }
      // const existingPlan = data.find((plan) => plan.id === id);
      // const activePlansCount = data.filter((plan) => plan.status === "1").length;

      // const isFreePlanActive = existingPlan.is_paid === "0";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("name", name);
      formData.append("duration", duration);
      formData.append("duration_type", durationtype);
      formData.append("price", price);
      // if (!isFreePlanActive) {
      //   formData.append("is_paid", is_paid);
      //   toast.warning("Please adjust payment status manually if not modified.");
      // }
      //  else {
      //   formData.append("is_paid", is_paid);
      //   console.log(is_paid);
      // }
      // if (activePlansCount >= 4 && status === "1" && existingPlan.status !== "1") {
      //   formData.append("status", "0");
      //   toast.warning("The status has been changed to inactive as only four active statuses are permitted.");
      //   console.log(status);

      // } else {
      //   formData.append("status", existingPlan.status);
      // }
      // if (activePlansCount >= 4 && status === "1" && existingPlan.status !== "1") {
      //   toast.warning("Active status is converted to inactive. Only 4 active statuses are allowed.");
      // status = "0"; // Set status to inactive
      // }
      // formData.append("status", status);
      // formData.append("is_paid", "1");
      // formData.append("status", "0");
      formData.append("client", client);
      formData.append("employee", employee);
      formData.append("management", management);
      formData.append("event_limit", event_limit);
      formData.append("technical_support", technical_support);
      formData.append("website_tool", website_tool);
      formData.append("advanced_features", advanced_features);
      formData.append("enhanced_support", enhanced_support);
      const planData = await MakeApiCall(`api/v1/admin/edit-plan`, formData);

      if (planData && planData.status) {
        console.log(planData);
        const submittedData = planData.data;
        toast.success("Plan updated successfully");
      } else {
        toast.error(planData.message);
        console.log("planUser data is invalid or missing required properties");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setModal({ edit: false });
      setLoading(false);
      resetForm();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const handleWarning = () => {
    Swal.fire({
      icon: "warning",
      title: "Status Free",
      text: "Free plan already exists",
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };
  const onUpdatePaidStatus = async (is_paid, id) => {
    let checkPaid = "";
    if (is_paid === "Paid") {
      checkPaid = "1";
    } else if (is_paid === "Free") {
      checkPaid = "0";
    }
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("is_paid", checkPaid);
      const planData = await MakeApiCall(`api/v1/admin/edit-plan`, formData);
      if (planData && planData.status) {
        fetchData();
        toast.success("Plan updated successfully");
      } else {
        handleWarning();
        fetchData();
        console.error("Error updating status:", planData.error);
        console.log(planData.error);
      }
    } catch (error) {
      toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  };
  const [statusChangeInProgress, setStatusChangeInProgress] = useState(false);
  const onUpdateActiveStatus = async (status, id) => {
    if (statusChangeInProgress) {
      toast.warning("Another status change is already in progress. Please wait.");
      return;
    }
    setStatusChangeInProgress(true);
    const activePlansCount = data.filter((plan) => plan.status === "1").length;
    if (activePlansCount >= 4 && status === "1") {
      toast.warning("Only four plans can be active simultaneously.");
      setStatusChangeInProgress(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("status", status);
      const planData = await MakeApiCall(`api/v1/admin/edit-plan`, formData);

      if (planData && planData.status) {
        toast.success("Status updated successfully. Please wait for the page to refresh.");
        setStatusChangeInProgress(false);
        // Refresh the data to reflect the changes
        fetchData();
      } else {
        toast.error("Failed to update status");
        console.error("Error updating status:", planData.error);
      }
    } catch (error) {
      toast.error("Error updating status");
      console.error("Error updating status:", error);
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setEditFormData({
          name: item.name,
          id: item.id,
          duration: item.duration,
          durationtype: item.duration_type,
          price: item.price,
          is_paid: item.is_paid,
          status: item.status,
          client: item.features.client,
          employee: item.features.employee,
          event_limit: item.features.event_limit,
          management: item.features.management,
          technical_support: item.features.technical_support,
          website_tool: item.features.website_tool,
          advanced_features: item.features.advanced_features,
          enhanced_support: item.features.enhanced_support,
        });
        {
          console.log(item.status);
        }
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };
  const handleToggle = (itemid, statusCode) => {
    console.log(statusCode);
    setPlanStates((prevPlanStates) => ({
      ...prevPlanStates,
      [itemid]: statusCode, // Update the state for the specific item
    }));

    onUpdateActiveStatus(statusCode, itemid);
  };
  // Pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePaidChange = (value, projectId) => {
    setPaidValues((prevState) => ({
      ...prevState,
      [projectId]: value,
    }));
  };

  return (
    <React.Fragment>
      <Head title="Project Card"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Create Your Plan</BlockTitle>
              <BlockDes className="text-soft">You have total {data?.length || 0} plan</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Add Plan</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {data?.length === 0 ? (
          <div className="fs-2 fw-bolder justify-content-end d-flex flex-column h-225px text-center">
            No Plan is available
          </div>
        ) : (
          ""
        )}
        <Block>
          <Row className="g-gs">
            {currentItems &&
              currentItems.map((item, idx) => {
                return (
                  <Col sm="6" lg="4" xxl="3" key={item.id}>
                    <ProjectCard>
                      <div className="project-head">
                        <a
                          href="#title"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="project-title"
                        >
                          <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} />
                          <div className="project-info">
                            <h6 className="title">{item.name}</h6>
                          </div>
                        </a>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                          >
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li onClick={() => onEditClick(item.id)}>
                                <DropdownItem
                                  tag="a"
                                  href="#edit"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="edit"></Icon>
                                  <span>Edit Plan</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#remove"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    deleteData(item.id);
                                    // handleAdvanced3()
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Remove Plan</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <ul className="team-statistics py-1">
                        <li>
                          <span>{item.duration}</span>
                          <span>
                            {item.duration_type === "d" ? (
                              <span>Days</span>
                            ) : item.duration_type === "m" ? (
                              <span>Month</span>
                            ) : null}
                          </span>
                        </li>
                        <li>
                          <span>${item.price}</span>
                          <span>Rate Of Plan</span>
                        </li>
                      </ul>
                      <div className="d-flex justify-content-between my-1">
                        <div className="fs-4 fw-bold">Plan Features</div>
                      </div>
                      <ul className="team-statistics py-1">
                        <li>
                          <span>{item?.features?.client}</span>
                          <span>Clients</span>
                        </li>
                        <li>
                          <span>{item?.features?.employee}</span>
                          <span>Employee</span>
                        </li>
                        <li>
                          <span>{item?.features?.event_limit}</span>
                          <span>Event-Limit</span>
                        </li>
                      </ul>
                      <div className="d-flex overflow-auto h-200px mx-1">
                        <div className="row w-100">
                          <div className="col-12 ">
                            <div>
                              <span className="fw-bold">
                                Other Features <br />
                              </span>
                            </div>
                            <ul className="pricing-features">
                              {features.map((feature, index) => {
                                return (
                                  <li key={index}>
                                    <span className="fs-6">
                                      <label className="align-center">
                                        <input
                                          disabled
                                          type="checkbox"
                                          className="mx-2 "
                                          style={{ height: 20, width: 20, backgroundColor: "red" }}
                                          defaultChecked={item.features[feature.key] === "yes" ? true : false}
                                        />
                                        {feature.label}
                                      </label>
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between flex-row my-2">
                        <div className="my-2">
                          <div className="preview-title fw-bold fs-6 col-12 mx-1">Select Subscription Status</div>
                          <div className="col-12">
                            <div className="custom-control custom-radio mx-1">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id={`customRadioPaid_${item.id}`}
                                name={`customRadioPaid_${item.id}`}
                                defaultChecked={item.is_paid === "1" ? true : false}
                                onChange={() => handlePaidChange("Paid", item.id)}
                                onClick={() => onUpdatePaidStatus("Paid", item.id)}
                              />
                              <label className="custom-control-label" htmlFor={`customRadioPaid_${item.id}`}>
                                Paid
                              </label>
                            </div>
                            <div className="custom-control custom-radio mx-1">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id={`customRadioFree_${item.id}`}
                                name={`customRadioPaid_${item.id}`}
                                defaultChecked={item.is_paid === "0" ? true : false}
                                onChange={() => handlePaidChange("Free", item.id)}
                                onClick={() => onUpdatePaidStatus("Free", item.id)}
                              />
                              <label className="custom-control-label" htmlFor={`customRadioFree_${item.id}`}>
                                Free
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="my-2">
                          <div className="preview-title fw-bold fs-6 col-12">Select Plan Status</div>
                          <div className="col-12">
                            <div className="preview-block">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch_${item.id}`}
                                  defaultChecked={item.status === "1" ? true : false}
                                  checked={planStates[item.id]}
                                  onChange={() => handleToggle(item.id, item.status == "1" ? "0" : "1")}
                                />
                                <label className="custom-control-label" htmlFor={`customSwitch_${item.id}`}>
                                  {item.status === "1" ? "Active" : "Inactive"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ProjectCard>
                  </Col>
                );
              })}
          </Row>
          {data?.length === 0 ? null : (
            <div className="mt-3">
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data?.length || 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          )}
        </Block>
        <FormModal
          modal={modal.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
        />
        <FormModal
          modal={modal.edit}
          modalType="edit"
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
        />
      </Content>
    </React.Fragment>
  );
};
export default ProjectCardPage;
