import { getCookie } from "../../utils/Utils";

const baseUrl = "https://api.ipartydjcrm.com/api/v1";
// let token = process.env.REACT_APP_AUTH_TOKEN;

// Delet API to delete Event from event List =================================
export const deleteEvent = async ({ id = "" }) => {
  const formData = new FormData();

  if (id !== "") {
    formData.append("id", id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/events/delete-event`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete client from list =================================
export const deleteClient = async ({ id = "" }) => {
  const formData = new FormData();

  if (id !== "") {
    formData.append("id", id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-client`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the client");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete package from list =================================
export const deletePackage = async ({ package_id = "" }) => {
  const formData = new FormData();

  if (package_id !== "") {
    formData.append("package_id", package_id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/package/delete-package`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the package");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete venue from list =================================
export const deleteVenue = async ({ venue_id = "" }) => {
  const formData = new FormData();

  if (venue_id !== "") {
    formData.append("venue_id", venue_id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/venue/delete-venue`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the venue");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete vender from list =================================
export const deleteVender = async ({ vender_id = "" }) => {
  const formData = new FormData();

  if (vender_id !== "") {
    formData.append("vender_id", vender_id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/vender/delete-vender`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the vender");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete employee from list =================================
export const deleteEmployee = async ({ id = "" }) => {
  const formData = new FormData();

  if (id !== "") {
    formData.append("id", id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/employee/delete-employee`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the employee");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// delete Enquiry from enquiry list
export const deleteEnquiry = async ({ id = "" }) => {
  const formData = new FormData();

  if (id !== "") {
    formData.append("id", id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/enquiry/delete-enquiry`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete Mix from mixes =================================
export const deleteMixes = async (data) => {
  const values = data;
  const formData = new FormData();

  if (values.id) {
    formData.append("mixes_id", values.id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-mixes`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete video from videos =================================
export const deleteVideos = async (data) => {
  const values = data;
  const formData = new FormData();

  if (values.id) {
    formData.append("video_id", values.id);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-videos`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete imge from images =================================
export const deleteImages = async (data) => {
  const values = data;

  const formData = new FormData();

  if (values.id) {
    formData.append("image_id", values.id);
  }

  if (values.category) {
    formData.append("category", values.category);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-images`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};


// Delet API to delete deleteyoutublink from deleteyoutublinks =================================
export const deleteyoutublink = async (data) => {
  const values = data;

  const formData = new FormData();

  if (values) {
    formData.append("link_id", values);
  }


  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-youtube-links`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};


// Delet API to delete deleteMixeUrl from MixeUrls =================================
export const deleteMixeUrl = async (data) => {
  const values = data;

  const formData = new FormData();

  if (values) {
    formData.append("link_id", values);
  }


  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-mixes-url`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};


// Delet API to delete deleteVendor from deleteVendors =================================
export const deleteVendor = async (data) => {
  const values = data;

  const formData = new FormData();

  if (values) {
    formData.append("vendor_id", values);
  }


  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/delete-vendor`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Delet API to delete Available time slot =================================
export const deleteAvailableTimeSlot = async (data) => {
  const values = data;

  const formData = new FormData();

  if (values) {
    formData.append("event_id", values);
  }


  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/enquiry/delete-event-time-slot`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while deleting the event");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};
