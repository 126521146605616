import React from 'react'

const Artists = () => {
  return (
    <div>
      Artists page
    </div>
  )
}

export default Artists
