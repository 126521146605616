import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import { useQuery } from "react-query";
import { getEventsList, getSlotAvailability } from "../../http/get/getApi";
import Calender from "../components/Calender";

function CalenderComponent() {
  
  const {
    data: events,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["get-events"],
    queryFn: () => getEventsList({ id: "", page: "", limit: "", search: "" }),
    staleTime: 5000,
    cacheTime: 300000, 
    retry: 1,
  });

  const eventsData = Array.isArray(events?.data)
    ? events.data
        .map((event) => {
          if (!event || !event.start_date_time || !event.end_date_time || !event.event_name || !event.event_type_name) {
            return null;
          }

          const startDate = new Date(event.start_date_time).toISOString().split("T")[0];
          const endDate = new Date(event.end_date_time).toISOString().split("T")[0];

          return {
            id: "default-event-id-" + event.id,
            title: event.event_name,
            start: startDate,
            end: endDate,
            description: event.event_type_name,
          };
        })
        .filter((event) => event !== null)
    : [];



  return (
    <div>
      <NavigationHeader heading={"Calendar"} buttonInnerText={""} />
      <Calender events1={eventsData} />
    </div>
  );
}

export default CalenderComponent;
