import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Homepage from "../pages/Home Page Setup/Homepage";
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import LayoutApp from "../layout/Index-app";
import ProjectCardPage from "../pages/prebuilt/projects/ProjectCard";
import LogoChange from "../pages/prebuilt/Logo Separation/LogoChange";
import FileManager from "../pages/Home Page Setup/Homepage";
import FileManagerFiles from "../pages/Home Page Setup/Homepage";
import FileManagerShared from "../pages/Home Page Setup/Homepage";
import FileManagerStarred from "../pages/Home Page Setup/Homepage";
import FileManagerRecovery from "../pages/Home Page Setup/Homepage";
import FileManagerSettings from "../pages/Home Page Setup/Homepage";
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import AddEvent from "../pages/event/AddEvent";
import Trending from "../pages/Trending";
import Albums from "../pages/Albums";
import Artists from "../pages/Artists";
import ClientListPage from "../pages/Client/ClientListPage";
import CreateClientPage from "../pages/Client/CreateClientPage";
import EmployeeListPage from "../pages/Employee/EmployeeListPage";
import CreateEmployeePage from "../pages/Employee/CreateEmployeePage";
import VenueListPage from "../pages/Venue/VenueListPage";
import CreateVenuePage from "../pages/Venue/CreateVenuePage";
import PackageListPage from "../pages/Package/PackageListPage";
import CreatePackagePage from "../pages/Package/CreatePackagePage";
import VenderListPage from "../pages/Vender/VenderListPage";
import CreateVenderPage from "../pages/Vender/CreateVenderPage";
import UserProfileRegular from "../pages/prebuilt/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/prebuilt/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/prebuilt/user-manage/UserProfileNotification";
import EventListPage from "../pages/EventList/EventListPage";
import CompanyInfo from "../pages/prebuilt/Company List/CompanyInfo";
import PlanRegistration from "../pages/prebuilt/Registration Plans/PlanRegistration";
import CreateServiceProfile from "../pages/Profile/createServiceProfile";
import EnquiryList from "../pages/DjEnquiryList/EnquiryList";
import UserProfile from "../pages/Profile/UserProfile";
import SuspendPage from "../pages/error/suspendPage";
import MonthlyandYearlyPlan from "../pages/prebuilt/Registration Plans/MonthlyandYearlyPlan";
import CalenderComponent from "../pages/EventCalendar/CalenderComponent";
// import StripPayment from "../pages/prebuilt/Registration Plans/StripePayment";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<Layout app={{ icon: "dashboard", theme: "bg-purple-dim", text: "Dashboard" }} />}
      >
        <Route index element={<Homepage />}></Route>
        <Route path="dashboard" element={<Homepage />}></Route>
        <Route path="Trending" element={<Trending />}></Route>
        <Route path="Albums" element={<Albums />}></Route>
        <Route path="Artists" element={<Artists />}></Route>
        <Route path="add-event" element={<AddEvent />}></Route>
        <Route path="suspend" element={<SuspendPage />}></Route>
        

        <Route path="event-list">
          <Route index element={<EventListPage />} />
        </Route>
        <Route path="calendar">
          <Route index element={<CalenderComponent />} />
        </Route>

        {/* pre built pages  */}
        <Route path="project-card" element={<ProjectCardPage />}></Route>
        <Route path="site-setting" element={<LogoChange />}></Route>
        <Route path="company-list" element={<CompanyInfo />}></Route>
        {/* <Route path="plan-selection" element={<PlanRegistration />}></Route> */}
        <Route path="plan-selection" element={<MonthlyandYearlyPlan />}></Route>
        {/* <Route path="stripe" element={< />}></Route> */}

        <Route path="client-list">
          <Route index element={<ClientListPage />} />
          <Route path="create-client" element={<CreateClientPage />} />
          <Route path=":id" element={<CreateClientPage />} />
        </Route>

        <Route path="employee-list">
          <Route index element={<EmployeeListPage />} />
          <Route path="create-employee" element={<CreateEmployeePage />} />
          <Route path=":id" element={<CreateEmployeePage />} />
        </Route>

        <Route path="venue-list">
          <Route index element={<VenueListPage />} />
          <Route path="create-venue" element={<CreateVenuePage />} />
          <Route path=":id" element={<CreateVenuePage />} />
        </Route>

        <Route path="vendor-list">
          <Route index element={<VenderListPage />} />
          <Route path="create-vender" element={<CreateVenderPage />} />
          <Route path=":id" element={<CreateVenderPage />} />
        </Route>

        <Route path="package-list">
          <Route index element={<PackageListPage />} />
          <Route path="create-package" element={<CreatePackagePage />} />
          <Route path=":id" element={<CreatePackagePage />} />
        </Route>

        <Route path="Company-profile">
          {/* <Route index element={<ServicesList />} />
          <Route path="create-package" element={<CreateServiceProfile />} /> */}
          <Route index element={<UserProfile />} />
          <Route path="edit-profile" element={<CreateServiceProfile />} />
          {/* <Route index element={<CreateServiceProfile />} /> */}
          {/* <Route path=":id" element={<createServiceProfile />} /> */}
        </Route>

        <Route path="inquiry-list">
          <Route index element={<EnquiryList />} />
          <Route path="create-package" element={<CreatePackagePage />} />
          <Route path=":id" element={<CreatePackagePage />} />
        </Route>

        <Route>
          <Route path="user-profile-notification" element={<UserProfileNotification />}></Route>
          <Route path="user-profile-regular" element={<UserProfileRegular />}></Route>
          {/* <Route path="user-profile-activity" element={<UserProfileActivity />}></Route> */}
          <Route path="user-profile-setting" element={<UserProfileSetting />}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`}>
        <Route element={<LayoutApp app={{ icon: "folder", theme: "bg-orange-dim", text: "File Manager" }} />}>
          <Route path="app-file-manager">
            <Route index element={<FileManager />}></Route>
            <Route path="files" element={<FileManagerFiles />}></Route>
            <Route path="starred" element={<FileManagerStarred />}></Route>
            <Route path="shared" element={<FileManagerShared />}></Route>
            <Route path="recovery" element={<FileManagerRecovery />}></Route>
            <Route path="settings" element={<FileManagerSettings />}></Route>
          </Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
