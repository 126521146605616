import React, { useState } from "react";
import { Button, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../../../../components/Component";
import Required from "../../Required";

const UpdatePassword = ({ AddEmployeeModal, isOpen }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSave = () => {
    // Password validation logic
    if (!password) {
      setPasswordError("Password is required");
      return;
    }

    // Check if password meets requirements
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, contain at least one number, and one capital letter."
      );
      return;
    }

    // If all validations pass, proceed with saving the password
    // Example: call an API to update the password

    // Clear any previous error messages
    setPasswordError("");

    // Close the modal or perform any other actions
    // AddEmployeeModal();
  };

  return (
    <>
      <Modal size="sm" isOpen={isOpen} toggle={AddEmployeeModal}>
        <ModalHeader
          toggle={AddEmployeeModal}
          close={
            <Button className="close" onClick={AddEmployeeModal}>
              <Icon name="cross" />
            </Button>
          }
        >
          Update password
        </ModalHeader>
        <ModalBody className="pt-0">
          <Label for="password" className="mt-2">
            New password
            <Required />
          </Label>
          <Input
            type="text"
            id="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={handlePasswordChange}
          />
          {passwordError && <div className="text-danger">{passwordError}</div>}
          <div className="d-flex justify-content-end">
            <Button className="mt-2" type="button" color="primary" onClick={handleSave}>
              <span>Save</span>
              <em className="icon ni ni-save"></em>
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdatePassword;
