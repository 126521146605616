let menu = [
  {
    heading: "Dashboards",
  },
  {
    icon: "dashboard",
    text: "Overview",
    link: "/",
    dashboard: true,
  },
  {
    heading: "Discover",
  },
  {
    icon: "reports",
    text: "Feed",
    active: false,
    subMenu: [
      {
        icon: "cards",
        text: "Project Cards",
        link: "/Trending",
      },
      {
        icon: "view-list-fill",
        text: "Project List",
        link: "/Trending",
      },
    ],
  },
  {
    icon: "calendar-alt",
    text: "Events",
    active: false,
    subMenu: [
      {
        icon: "user-list",
        text: "User List - Regular",
        link: "/Trending",
      },
    ],
  },
  {
    icon: "live",
    text: "Now Streaming",
    active: false,
    subMenu: [
      {
        icon: "headphone",
        text: "Shows",
        link: "/Trending",
      },
    ],
  },
  {
    icon: "card-view",
    text: "Products",
    active: false,
    subMenu: [
      {
        icon: "view-list-fill",
        text: "Product List",
        link: "/Trending",
      },
      {
        icon: "cards",
        text: "Product Card",
        link: "/Trending",
      },
      {
        icon: "article",
        text: "Product Details",
        link: "/Trending",
      },
    ],
  },
  {
    icon: "files",
    text: "Invoice",
    active: false,
    subMenu: [
      {
        icon: "view-list-fill",
        text: "Invoice List",
        link: "/Trending",
      },
      {
        icon: "article",
        text: "Invoice Details",
        link: "/Trending",
      },
    ],
  },
  // {
  //   heading: "Plan Structure",
  // },
  // {
  //   icon: "clipboad-check",
  //   text: "Plans",
  //   link: "/project-card",
  //   dashboard: true,
  // },
];
menu = [
  {
    icon: "dashboard",
    text: "Overview",
    link: "/",
    dashboard: true,
  },
  {
    icon: "calendar-alt",
    text: "Add event",
    link: "/add-event",
  },
  {
    icon: "icon ni ni-list-thumb-alt",
    text: "Event list",
    link: "/event-list",
  },
  {
    icon: "user-c",
    text: "Clients",
    link: "/client-list",
  },
  {
    icon: "icon ni ni-users",
    text: "Employee",
    link: "/employee-list",
  },
  {
    icon: "icon ni ni-location",
    text: "Venue",
    link: "/venue-list",
  },
  {
    icon: "icon ni ni-home-alt",
    text: "Venue",
    link: "/vendor-list",
  },
  {
    icon: "package",
    text: "Package",
    link: "/package-list",
  },
  // {
  //   icon: "profile",
  //   text: "Services",
  //   link: "/Company-profile",
  // },
];

export default menu;
