import React from 'react'

const Albums = () => {
  return (
    <div>
      Album page
    </div>
  )
}

export default Albums
