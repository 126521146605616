import React, { useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import Swal from "sweetalert2";

const UploadMultipleImages = ({ label, name, handleAddImage, isCompanyProfileEditing,setHasUnsavedChanges }) => {
  const fileInputRef = useRef(null);

  const [dragging, setDragging] = useState(false);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert the FileList to an array
    // console.log('files :', files);
  
    // Check if the array is empty and show a message if true
    if (files.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'No files selected',
        text: 'Please upload at least one image.',
      });
      return;
    }
  
    const base64Strings = []; // Array to hold the base64 strings
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    let allFilesValid = true; // Flag to track if all files are valid
  
    files.forEach((file) => {
      if (!file.type.startsWith('image/')) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid file type',
          text: 'Please upload only image files.',
        });
        allFilesValid = false;
        return;
      }
  
      if (file.size > maxSize) {
        Swal.fire({
          icon: 'error',
          title: 'File too large',
          text: 'Each image must be less than 10MB.',
        });
        allFilesValid = false;
        return;
      }
    });
  
    // If not all files are valid, return early
    if (!allFilesValid) {
      return;
    }
  
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        // console.log('base64String :', base64String);
        base64Strings.push(base64String);
  
        // If this is the last file, call handleAddImage with all the base64 strings
        if (base64Strings.length === files.length) {
          handleAddImage(base64Strings);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  



  const handleClick = () => {
    setHasUnsavedChanges(true)
    fileInputRef.current.click();
    
  };

  const handleDragOver = (e) => {
    
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

   // Handle drag leave event
   const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    setHasUnsavedChanges(true)
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files); // Convert the FileList to an array
    // console.log('Dropped files:', files);

    // Check if the array is empty and show a message if true
    if (files.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'No files selected',
        text: 'Please upload at least one image.',
      });
      return;
    }

    const base64Strings = []; // Array to hold the base64 strings
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    let allFilesValid = true; // Flag to track if all files are valid

    files.forEach((file) => {
      if (!file.type.startsWith('image/')) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid file type',
          text: 'Please upload only image files.',
        });
        allFilesValid = false;
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          icon: 'error',
          title: 'File too large',
          text: 'Each image must be less than 10MB.',
        });
        allFilesValid = false;
        return;
      }
    });

    // If not all files are valid, return early
    if (!allFilesValid) {
      return;
    }

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        // console.log('base64String :', base64String);
        base64Strings.push(base64String);

        // If this is the last file, call handleAddImage with all the base64 strings
        if (base64Strings.length === files.length) {
          handleAddImage(base64Strings);
        }
      };
      reader.readAsDataURL(file);
    });
  };


  return (
    <div>
      <label className="text-dark">{label}</label>
      <br />
      <div
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="upload-drop-zone mt-2"
        style={{
          // border: "2px dashed #ccc",
          border: dragging ? "2px solid #00f" : "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {
          isCompanyProfileEditing ? (

            <Button disabled
             color="primary"
             >
              <Spinner size="sm" />
              <span> Loading... </span>
            </Button>
          ) : (
            <Button type="button"
             className="btn btn"
            color="primary"
             >
              Select Images
            </Button>
          )
        }
        <input
          type="file"
          name={name}
          disabled={isCompanyProfileEditing}
          accept="image/*"
          multiple
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <p className="mt-3">or drag and drop here</p>
        <p></p>
      </div>
    </div>
  );
};

export default UploadMultipleImages;
