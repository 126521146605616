import { Button, Input, Label } from "reactstrap";
import uploadImage from "../../assets/images/uploadImage.png";
import { useState } from "react";

const UploadImage = ({ label = "Upload Image", name, formik, disabled = false, setHasUnsavedChanges, isViewOnly }) => {
  const [fileSelected, setFileSelected] = useState(false);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    // setHasUnsavedChanges(true);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Result is the base64 representation of the image
        const base64String = reader.result.split(",")[1];
        const imageData = `data:image/jpeg;base64,${base64String}`;
        formik.setFieldValue(name, imageData);
        setFileSelected(true);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    if (formik.values[name] === "") {
      document.getElementById("upload").click();
    }
  };

  const handleRemoveClick = () => {
    formik.setFieldValue(name, "");
    formik.setFieldValue("imagePresent", 0);
    setFileSelected(false);
    // setHasUnsavedChanges(true);
  };

  return (
    <>
    {
      isViewOnly && name === "package_image" ? <Label for="upload">Package Picture</Label> : isViewOnly ? <Label for="upload">Profile Picture</Label> : <Label for="upload" style={{marginLeft:"11px"}}>{label}</Label>
    }
      <div className="d-flex flex-column align-items-center">
        <div
          onClick={handleImageClick}
          style={{
            cursor: formik.values[name] ? "not-allowed" : "pointer",
          }}
        >
          <img
            src={formik.values[name] ? formik.values[name] :  uploadImage}
            alt="Preview"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case fallback also fails
              e.target.src = uploadImage; // Set fallback image if the URL is broken
            }}
            style={{ width: "100%", height: "170px", objectFit: "contain" }}
            className="mb-2"
          />
        </div>
        {!isViewOnly &&
          (formik.values[name] ? (
            <div className="d-flex justify-content-between">
              <Button color="danger" type="button" className="btn-dim" disabled={disabled} onClick={handleRemoveClick}>
                <em className="icon ni ni-trash"></em>
                <span>Remove</span>
              </Button>
              <Label for="upload" className="border mb-0 ms-2 rounded p-2 custom-file-upload">
                {fileSelected ? "Upload a new file" : "Choose a file"}
              </Label>
              <Input
                type="file"
                id="upload"
                accept="image/*"
                name={name}
                onChange={handleFileChange}
                disabled={disabled}
                onBlur={formik.handleBlur}
                style={{ display: "none" }}
              />
            </div>
          ) : (
            <>
              <Label for="upload" className="border w-80 text-center rounded p-2 custom-file-upload">
                {fileSelected ? "Upload a new file" : "Choose a file"}
              </Label>
              <Input
                type="file"
                id="upload"
                accept="image/*"
                name={name}
                onChange={handleFileChange}
                disabled={disabled}
                onBlur={formik.handleBlur}
                style={{ display: "none" }}
              />
            </>
          ))}

        {/* {formik.values[name] ? (
          <div className="d-flex justify-content-between">
            <Button color="danger" type="button" className="btn-dim" disabled={disabled} onClick={handleRemoveClick}>
              <em class="icon ni ni-trash"></em>
              <span>Remove</span>
            </Button>
            <Label for="upload" className="border mb-0 ms-2  rounded p-2 custom-file-upload">
              {fileSelected ? "Upload a new file" : "Choose a file"}
            </Label>
            <Input
              type="file"
              id="upload"
              accept="image/*"
              name={name}
              onChange={handleFileChange}
              disabled={disabled}
              onBlur={formik.handleBlur}
              placeholder="upload"
              style={{ display: "none" }}
            />
          </div>
        ) : (
          <>
            <Label for="upload" className="border w-80 text-center rounded p-2 custom-file-upload">
              {fileSelected ? "Upload a new file" : "Choose a file"}
            </Label>
            <Input
              type="file"
              id="upload"
              accept="image/*"
              name={name}
              onChange={handleFileChange}
              disabled={disabled}
              onBlur={formik.handleBlur}
              style={{ display: "none" }}
            />
          </>
        )} */}
      </div>
    </>
  );
};
export default UploadImage;
