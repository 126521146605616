import { useNavigate } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';

const ExceededLimitsModal = ({ isVisible, onClose, exceededLimits }) => {

    const navigate = useNavigate();

    return(

  <Modal isOpen={isVisible} toggle={onClose}>
    <ModalHeader toggle={onClose}>Limit Exceeded</ModalHeader>
    <ModalBody>
      <Card>
        <CardBody>
          <CardTitle tag="h5">You have exceeded the following limits:</CardTitle>
          <CardText>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
              {exceededLimits?.map((limit, index) => (
                <li key={index}>{limit}</li>
              ))}
            </ul>
          </CardText>
        </CardBody>
      </Card>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={() => navigate("/Company-profile/edit-profile")}>Go to Profile</Button>{' '}
      {/* <Button color="danger" onClick={onClose}>Cancel</Button> */}
    </ModalFooter>
  </Modal>
  )
};


export default ExceededLimitsModal 
