import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
} from "../../../components/Component";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { productData } from "./ProductData";
import SimpleBar from "simplebar-react";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";
import Required from "../../components/Required";
import PuffLoader from "react-spinners/PuffLoader";
import {ShimmerTable} from "react-shimmer-effects";

const CompanyInfo = () => {
  const [companyData, setCompanyData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [smOption, setSmOption] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    frm_username: "",
    email: "",
    company_name: "",
    phone_number: "",
    address_1: "",
    created_at: "",
    password: "",
    country: "",
  });
  const [editId, setEditedId] = useState();
  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalRecordsShimmer, setTotalRecordsShimmer] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setCompanyData(responseData?.data?.list);
      setTotalRecords(responseData?.data?.totalRecords);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //scroll off when sidebar shows
  useEffect(() => {
    view.add ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [view.add]);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = companyData.filter((item) => {
        return item.company_name.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setCompanyData(filteredObject);
    } else {
      fetchData();
    }
  }, [onSearchText]);

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  // Function to reset form and state
  const resetForm = () => {
    setFormData({
      frm_username: "",
      email: "",
      company_name: "",
      phone_number: "",
      address_1: "",
      created_at: "",
      password: "",
      country: "",
    });
    reset({});
  };

  const onFormSubmit = async (form) => {
    setLoading(true);
    const { frm_username, email, phone_number, company_name, address_1, password, country } = form;
    try {
      const formData = new FormData();
      formData.append("username", frm_username);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("company_name", company_name);
      formData.append("address_1", address_1);
      formData.append("password", password);
      formData.append("country", country);
      const companyData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-companies`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });
      if (companyData && companyData.status) {
        const submittedData = companyData?.data;
        toast.success(`${frm_username} Company Information Added Successfully`);
        fetchData();
        resetForm();
        setLoading(false);
        setView({ edit: false });
      } else {
        toast.success(companyData.message);
        setLoading(false);
        console.log("data is invalid or missing required properties");
      }
    } catch (error) {
      setLoading(false);
      console.log("Error fetching user data:", error);
    }
  };

  // Function to handle editing an existing company
  const onEditSubmit = async (form) => {
    setLoading(true);
    const { id, frm_username, email, phone_number, company_name, address_1, password, country } = form;
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("first_name", frm_username);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("company_name", company_name);
      formData.append("address_1", address_1);
      formData.append("password", password);
      formData.append("country", country);
      const companyData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/edit-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      });
      if (companyData && companyData.status) {
        const submittedData = companyData?.data;
        toast.success(`${frm_username} Company Information Updated Successfully`);
        fetchData();
        resetForm();
        setView({ edit: false });
        setLoading(false);
      } else {
        toast.error(companyData.message);
        console.log("data is invalid or missing required properties");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error fetching user data:", error);
    }
  };

  const onEditClick = (id) => {
    companyData.forEach((item) => {
      if (item.id === id) {
        // const countryCode = item.country;
        // const phoneNumber = item.phone_number;
        // if (phoneNumber.startsWith("+" + countryCode)) {
        //   console.log("yes");
        //   const trackingCC = phoneNumber?.replace(new RegExp("^\\+?" + countryCode), "");
        setFormData({
          id: item.id,
          frm_username: item.first_name,
          email: item.email,
          phone_number: item.phone_number,
          company_name: item.company_name,
          address_1: item.address_1,
          password: item.password,
          country: item.country,
          created_at: item.created_at,
        });
        // } else console.log("no");
      }
    });
    setEditedId(id);
    setFiles([]);
    setView({ add: false, edit: true });
  };

  useEffect(() => {
    reset(formData);
  }, [formData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to delete a product
  const deleteProduct = (id) => {
    let defaultData = data;
    defaultData = defaultData.filter((item) => item.id !== id);
    setData([...defaultData]);
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = companyData?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    setTableLoading(true);
    // Simulating data fetch
    setTimeout(() => {
      setTableLoading(false);
    }, 2000);
  }, []);
  return (
    <React.Fragment>
      <Head title="Product List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Company Details</BlockTitle>
              <div className="d-flex flex-row">
                <span>Total Companies registration :</span>
                <span className="text-primary fw-semibold px-1">
                  {tableLoading ?  0: totalRecords}
                </span>
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: smOption ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                      <div className="form-control-wrap w-250px">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Quick search by company name"
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li> */}
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                          resetForm();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Company</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered overflow-auto">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                {tableLoading ? (
                  <div className="p-1">
                    <ShimmerTable row={6} col={7} />
                  </div>
                ) : (
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow className="text-primary fs-5">
                        <span>Id. No.</span>
                      </DataTableRow>
                      <DataTableRow className="text-primary fs-5">
                        <span>UserName</span>
                      </DataTableRow>
                      <DataTableRow className="text-primary fs-5">
                        <span>CompanyName</span>
                      </DataTableRow>
                      <DataTableRow className="text-primary fs-5">
                        <span>Email</span>
                      </DataTableRow>
                      <DataTableRow className="text-primary fs-5">
                        <span>Phone</span>
                      </DataTableRow>
                      <DataTableRow className="text-primary fs-5">
                        <span>Address</span>
                      </DataTableRow>
                      <DataTableRow className="text-primary fs-5">
                        <span>Registration Date</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>&nbsp;</span>
                      </DataTableRow>
                    </DataTableHead>
                    {currentItems &&
                      currentItems.map((item, idx) => {
                        return (
                          <DataTableItem key={item.id}>
                            <DataTableRow>
                              <span className="tb-sub">
                                <span className="tb-sub fs-6">{idx + 1}</span>
                              </span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">
                                <span className="title fs-6">{item.first_name}</span>
                              </span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub">
                                <span className="title fs-6">{item.company_name}</span>
                              </span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub fs-6">{item.email}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub fs-6">{item.phone_number}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub fs-6">{item.address_1}</span>
                            </DataTableRow>
                            <DataTableRow>
                              <span className="tb-sub fs-6">{item.created_at}</span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools">
                              <ul className="gx-1 my-n1">
                                <li className="me-n1">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      href="#more"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#edit"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              onEditClick(item.id);
                                              toggle("edit");
                                            }}
                                          >
                                            <Icon name="edit"></Icon>
                                            <span>Edit Product</span>
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#view"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              onEditClick(item.id);
                                              toggle("details");
                                            }}
                                          >
                                            <Icon name="eye"></Icon>
                                            <span>View Product</span>
                                          </DropdownItem>
                                        </li>
                                        {/* <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#remove"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              deleteProduct(item.id);
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Remove Product</span>
                                          </DropdownItem>
                                        </li> */}
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              </ul>
                            </DataTableRow>
                          </DataTableItem>
                        );
                      })}
                  </DataTableBody>
                )}

                <div className="card-inner">
                  {companyData?.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={companyData.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>

        <Modal isOpen={view.edit} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Information</h5>
              <div className="mt-4">
                <form noValidate onSubmit={handleSubmit(onEditSubmit)}>
                  <Row className="g-3">
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="product-title">
                          User Name
                          <Required />
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("frm_username", {
                            required: "This field is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message: "Only alphabets and spaces are allowed",
                            },
                            minLength: {
                              value: 3,
                              message: "Please enter at least 3 characters",
                            },
                          })}
                          maxLength={30}
                          value={formData.frm_username}
                          placeholder="Enter your First Name"
                          onChange={(e) => setFormData({ ...formData, frm_username: e.target.value })}
                        />
                        {errors.frm_username && <span className="invalid">{errors.frm_username.message}</span>}
                      </div>
                    </Col>
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="product-title">
                          Company Name
                          <Required />
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Company Name"
                            {...register("company_name", {
                              required: "This field is required",
                            })}
                            maxLength={30}
                            value={formData.company_name}
                            onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                          />
                          {errors.company_name && <span className="invalid">{errors.company_name.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="regular-price">
                          Email
                          <Required />
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "This is required",
                              pattern: {
                                value:
                                  // eslint-disable-next-line
                                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                message: "Enter a valid email address",
                              },
                              maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                            })}
                            className="form-control"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="sale-price">
                          Phone
                          <Required />
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Contact Number"
                            {...register("phone_number")}
                            value={formData.phone_number}
                            onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                          />
                          {errors.phone_number && <span className="invalid">{errors.phone_number.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="stock">
                          Password
                          <Required />
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            {...register("password", { required: "This is required" })}
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                          />
                          {errors.password && <span className="invalid">{errors.password.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="stock">
                          Country Code
                          <Required />
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Country Code"
                            {...register("country", {
                              required: "This is required",
                              pattern: {
                                value: /^[0-9+]+$/,
                                message: "Only numbers and '+' are allowed",
                              },
                            })}
                            value={formData.country}
                            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                          />
                          {errors.country && <span className="invalid">{errors.country.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="stock">
                          Address
                          <Required />
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Company Address"
                            {...register("address_1", { required: "This is required" })}
                            value={formData.address_1}
                            onChange={(e) => setFormData({ ...formData, address_1: e.target.value })}
                          />
                          {errors.address_1 && <span className="invalid">{errors.address_1.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col size="12">
                      <Button color="primary" type="submit" disabled={loading}>
                        <Icon className="plus"></Icon>
                        <span className="px-0 mx-0">Update Information</span>
                        {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={view.details} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="nk-modal-head">
              <h4 className="nk-modal-title title">
                Company <small className="text-primary fw-bold">{formData?.company_name}</small>
              </h4>
            </div>
            <div className="nk-tnx-details mt-sm-3">
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Company Name</span>
                  <span className="caption-text">{formData?.company_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">User Name</span>
                  <span className="caption-text">{formData?.frm_username}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Email</span>
                  <span className="caption-text">{formData?.email}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Phone </span>
                  <span className="caption-text">{formData?.phone_number}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Address</span>
                  <span className="caption-text"> {formData?.address_1}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Registared Date</span>
                  <span className="caption-text"> {formData?.created_at}</span>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>

        <SimpleBar
          className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
            view.add ? "content-active" : ""
          }`}
        >
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Add Company</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Row className="g-3">
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="product-title">
                      User Handler
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("frm_username", {
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Za-z\s]+$/,
                          message: "Only alphabets and spaces are allowed",
                        },
                        minLength: {
                          value: 3,
                          message: "Please enter at least 3 characters",
                        },
                      })}
                      maxLength={30}
                      value={formData.frm_username}
                      onChange={(e) => setFormData({ ...formData, frm_username: e.target.value })}
                    />
                    {errors.frm_username && <span className="invalid">{errors.frm_username.message}</span>}
                  </div>
                </Col>
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="product-title">
                      Company Name
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Company Name"
                        {...register("company_name", {
                          required: "This field is required",
                        })}
                        maxLength={30}
                        value={formData.company_name}
                        onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                      />
                      {errors.company_name && <span className="invalid">{errors.company_name.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="regular-price">
                      Email
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: "This is required",
                          pattern: {
                            value:
                              // eslint-disable-next-line
                              /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                            message: "Enter a valid email address",
                          },
                          maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                        })}
                        className="form-control"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="sale-price">
                      Contact Number
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Contact Number"
                        {...register("phone_number", { required: "This is required" })}
                        value={formData.phone_number}
                        onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                      />
                      {errors.phone_number && <span className="invalid">{errors.phone_number.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="stock">
                      Address
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Company Address"
                        {...register("address_1", { required: "This is required" })}
                        value={formData.address_1}
                        onChange={(e) => setFormData({ ...formData, address_1: e.target.value })}
                      />
                      {errors.address_1 && <span className="invalid">{errors.address_1.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="stock">
                      Password
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        {...register("password", { required: "This is required" })}
                        value={formData.password}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      />
                      {errors.password && <span className="invalid">{errors.password.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="stock">
                      Country Code
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Country Code"
                        {...register("country", {
                          required: "This is required",
                          pattern: {
                            value: /^[0-9+]+$/,
                            message: "Only numbers and '+' are allowed",
                          },
                        })}
                        value={formData.country}
                        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                      />
                      {errors.country && <span className="invalid">{errors.country.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col size="12" className="d-flex justify-center">
                  <Button color="primary" type="submit" className="w-200px" disabled={loading}>
                    <Icon className="plus"></Icon>
                    <span className="mx-auto">Add Information</span>
                    {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                  </Button>
                </Col>
              </Row>
            </form>
          </Block>
        </SimpleBar>

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}
      </Content>
    </React.Fragment>
  );
};

export default CompanyInfo;
