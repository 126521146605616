import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const MessageModal = ({ isOpen, toggle, enquiry_message }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Message</ModalHeader>
    <ModalBody>{enquiry_message.enquiry_message ? enquiry_message.enquiry_message : ""}</ModalBody>
    <ModalHeader>Reply</ModalHeader>
    <ModalBody>{enquiry_message.enquiry_reply ? enquiry_message.enquiry_reply : ""}</ModalBody>
  </Modal>
);

export default MessageModal;
