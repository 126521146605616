import { useNavigate } from "react-router";
import { Button, CardText, Progress } from "reactstrap";
import { Icon } from "../../components/Component";
import { motion } from "framer-motion";
import { getCompanyProfile } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../App";

const NavigationHeader = ({ heading, buttonInnerText, route, back, removeIcon = false, total, limit }) => {
  const navigate = useNavigate();

  const { setProfileProgress, profileprogress } = useContext(DataContext);

  const handleClick = () => {
    navigate(route);
    localStorage.removeItem("EventformData");
  };

  const priveHover = {
    hover: {
      x: -3,
    },
  };

  useEffect(() => {

    const updateProfile = async () => {
      const data = await getCompanyProfile();
      const profileProgress = data?.data?.profile?.profile_progress
        ? JSON.parse(data?.data?.profile?.profile_progress)
        : 0;
      setProfileProgress(profileProgress);
    };
    updateProfile();

  }, []);

  // const { data, isLoading: companyprofileLoading, isError } = useQuery('userProfile-data', getCompanyProfile);

  // const [progress, setCompanyData] = useState(0);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // const updateProfile = async () => {
  //   try {
  //     setLoading(true);
  //     const data = await getCompanyProfile();
  //     const profileProgress = data?.data?.profile?.profile_progress
  //       ? JSON.parse(data.data?.profile?.profile_progress)
  //       : 0;
  //     setCompanyData(profileProgress);
  //     sessionStorage.setItem("profileProgress", profileProgress);
  //     setLoading(false);
  //   } catch (error) {
  //     setError(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   const savedProgress = sessionStorage.getItem("profileProgress");
  //   if (savedProgress) {
  //     setCompanyData(JSON.parse(savedProgress));
  //   } else {
  //     updateProfile();
  //   }
  // }, []); // Empty dependency array to run only on component mount

  // useEffect(() => {
  //   if (progress !== 0) {
  //     sessionStorage.setItem("profileProgress", JSON.stringify(progress));
  //   }
  // }, [progress]); // Update session storage whenever progress changes

  // const blockCount = 5;
  // const blockPercentage = 20;

  // const filledBlocks = Math.floor(progress / blockPercentage);
  // const partialBlockWidth = ((progress % blockPercentage) / blockPercentage) * 100;

  return (
    <>
      {/* <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <Progress
          className="progress-lg mb-4"
          value={progress}
          style={{
            width: '100%',
            backgroundColor: '#d3d3d3', // Color of the remaining portion
            position: 'relative',
          }}
        >
          <div
            style={{
              width: `${progress}%`,
              backgroundColor: '#4caf50', // Color of the filled portion
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              zIndex: 1,
            }}
          ></div>
          <span style={{ position: 'relative', zIndex: 2 }}>{progress}%</span>
        </Progress>
      </div>
      {progress < 90 && (
        <CardText style={{ fontSize: '1rem', color: 'blue', textAlign: 'center', marginBottom: '20px' }}>
          Complete your profile for better reach and to attract more clients to your DJ profile.
        </CardText>
      )} */}

      {/* <div style={{ textAlign: "center", marginBottom: "10px", marginTop: "20px" }}>
        <span style={{ marginTop: "10px", display: "block" }}>{progress}%</span>
        {progress < 95 && (
          <CardText style={{ fontSize: "0.9rem", color: "blue", textAlign: "center", marginBottom: "10px" }}>
            Complete your profile.
          </CardText>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "300px",
            margin: "0 auto",
          }}
        >
          {[...Array(blockCount)].map((_, index) => (
            <div
              key={index}
              style={{
                width: "18%", // Ensure the blocks fit within the container, with space for gaps
                height: "10px",
                backgroundColor: index < filledBlocks ? "#4caf50" : "#d3d3d3",
                position: "relative",
              }}
            >
              
              {index === filledBlocks && progress % blockPercentage !== 0 && (
                <div
                  style={{
                    width: `${partialBlockWidth}%`,
                    backgroundColor: "#4caf50",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                ></div>
              )}
              
            </div>
          ))}
          
                <Icon name="info" className="size-md" style={{ fontSize: "1.2rem", position: "relative" }} />
            
        </div>
      </div> */}

      <div className="container-fluid">
        <div className=" nk-block-head d-flex justify-content-between  align-content-center mx-1 mt-4">
          <div className="d-flex ms-1">
            <h3 className="text-body">{heading}</h3>
            {/* <h5 className="align-self-center mx-2">
              ({total}/{limit})
            </h5> */}
          </div>
          {buttonInnerText && (
            <>
              {removeIcon ? (
                <Button 
                color="primary"
                // style={{
                //   background: "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)",
                //   border: "none",
                // }}
                // onMouseOver={(e) =>
                //   (e.currentTarget.style.background =
                //     "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)")
                // }
                // onMouseOut={(e) =>
                //   (e.currentTarget.style.background =
                //     "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)")
                // }
                 onClick={handleClick}>
                  <span>{buttonInnerText}</span>
                </Button>
              ) : (
                <motion.div whileHover={"hover"}>
                  <Button 
                  style={{
                    background: "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)",
                    border: "none",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.background =
                      "linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%)")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.background =
                      "linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%)")
                  }
                  // color="primary" 
                  onClick={() => navigate(route)}>
                    {back ? (
                      <motion.span variants={priveHover}>
                        {/* <Icon className="icon ni ni-arrow-long-left"></Icon> */}
                        {/* <Icon className="icon ni ni-arrow-left p-1"></Icon> */}
                      </motion.span>
                    ) : (
                      
                      ""
                      // <Icon className="ni-plus"></Icon>
                    )}
                    <span>{buttonInnerText}</span>
                  </Button>
                </motion.div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default NavigationHeader;
