import React from "react";
import { Line } from "react-chartjs-2";

export const MonthlyOverview = (eventdata) => {
  if (!eventdata || !eventdata.labels || !eventdata.datasets || eventdata.datasets.length === 0) {
    return null; // Handle case where data is not properly defined
  }

  return {
    labels: eventdata.labels,
    dataUnit: "BTC",
    datasets: [
      {
        label: "Sales Overview",
        color: "#798bff",
        fill: true,
        backgroundColor: "rgba(10,120,89,0.2)",
        borderColor: "#798bff",
        barPercentage: 0.1,
        categoryPercentage: 0.1,
        borderWidth: 2,
        lineTension: 0.1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBorderColor: "#798bff",
        pointHoverBackgroundColor: "#fff",
        data: eventdata.datasets[0]?.data || [], // Access the data from the first dataset
      },
    ],
  };
};

export const WeeklyOverview = (eventdata) => {
  if (!eventdata || !eventdata.labels || !eventdata.datasets || eventdata.datasets.length === 0) {
    return null; // Handle case where data is not properly defined
  }

  return {
    labels: eventdata.labels,
    dataUnit: "BTC",
    datasets: [
      {
        label: "Sales Overview",
        color: "#798bff",
        fill: true,
        backgroundColor: "rgba(10,120,89,0.2)",
        borderColor: "#798bff",
        barPercentage: 0.1,
        categoryPercentage: 0.1,
        borderWidth: 2,
        lineTension: 0.1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBorderColor: "#798bff",
        pointHoverBackgroundColor: "#fff",
        data: eventdata.datasets[0]?.data || [], // Access the data from the first dataset
      },
    ],
  };
};

export const DailyReportEvent = ({ auOverview, eventdata }) => {
  let montdata = null;
  let weekdata = null;
  
  if (auOverview === "7 days") {
    weekdata = eventdata;
  } else if (auOverview === "1 month") {
    montdata = eventdata;
  }
  
  const Revenue = auOverview === "7 days" ? WeeklyOverview(weekdata) : MonthlyOverview(montdata);
  
  if (!Revenue) {
    return null; // Handle case where Revenue is null (no valid data)
  }

  return (
    <Line
      className="sales-overview-chart"
      data={Revenue}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: "13px",
            },
            titleColor: "#6783b8",
            titleMarginBottom: 6,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: "12px",
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              beginAtZero: true,
              color: "#9eaecf",
              font: {
                size: "11px",
              },
              callback: function (value) {
                return value;
              },
              padding: 10,
              min: 0,
              stepSize: 2,
            },
            grid: {
              tickMarkLength: 0,
            },
          },
          x: {
            display: true,
            ticks: {
              color: "#9eaecf",
              font: {
                size: "9px",
              },
              source: "auto",
              padding: 10,
            },
            grid: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
            },
          },
        },
      }}
    />
  );
};

export default DailyReportEvent;
