import { useEffect } from "react";
import { useState } from "react";
import CustomReactSelect from "react-select";
import { Badge, Label } from "reactstrap";
import { useTheme } from "../../layout/provider/Theme";
import Required from "../../pages/components/Required";
import AddNewOpetionModal from "./AddNewOpetionModal";

const ReactSelect = ({
  value,
  options,
  id,
  name,
  onChange,
  onBlur,
  styles,
  placeholder,
  disabled = false,
  label,
  required,
  addOpetionsForm,
  api,
  postId,
  updateAttireOptions,
  updateEventTypeOptions,
  updateNextActionOptions,
}) => {
  const { skin } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(options.find((option) => option.value === value));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid #384d69",
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      border: "1px solid #384d69",
    }),
  };

  useEffect(() => {
    const selectedOpetions = options.find((option) => option.value === value);
    if (selectedOpetions === undefined) {
      setSelected(null);
    } else {
      setSelected(selectedOpetions);
    }
  }, [value]);

  return (
    <>
      {label && (
        <Label for={id} className="d-flex justify-content-between align-items-center">
          <div>
            {label}
            {required && <Required />}
          </div>
          {/* {addOpetionsForm && !disabled && (
            <Badge onClick={toggle} color="primary">
              + Add new
            </Badge>
          )} */}
        </Label>
      )}
      <CustomReactSelect
        options={options}
        id={id}
        name={name}
        value={selected}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        styles={skin === "dark" ? { ...darkModeStyles, ...styles } : styles}
      />

      <AddNewOpetionModal
        isOpen={isOpen}
        toggle={toggle}
        label={label}
        api={api}
        id={postId}
        updateAttireOptions={updateAttireOptions}
        updateEventTypeOptions={updateEventTypeOptions}
        updateNextActionOptions={updateNextActionOptions}
      />
    </>
  );
};

export default ReactSelect;
