import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Setup the localizer by providing the moment (or globalize) Object
const localizer = momentLocalizer(moment);

const EventComponent = ({ event }) => {
    return (
      <div>
        <strong>{event.title}</strong>
        <p>{event.description}</p>
      </div>
    );
  };

const EventCalendar = ({ events }) => {
    // console.log("events***",events.data)
  const eventStyleGetter = (event) => {
    let backgroundColor = event.title.includes("Available") ? "green" : "green";
    let style = {
      backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  return (
    <div style={{ height: "500px" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        eventPropGetter={eventStyleGetter}
        components={{
            event: EventComponent,
          }}
      />
    </div>
  );
};

export default EventCalendar;
