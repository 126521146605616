import { getCookie } from "../../utils/Utils";

const baseUrl = "https://api.ipartydjcrm.com/api/v1";
// let token = process.env.REACT_APP_AUTH_TOKEN;

// Get API to fetch Client details =================================
export const getClientsList = async (values) => {
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("id", values.id);
  }
  if (values.page !== "") {
    formData.append("page", values.page);
  }
  if (values.limit !== "") {
    formData.append("limit", values.limit);
  }
  if (values.search !== "") {
    formData.append("search_keyword", values.search);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-client`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

// Get API to fetch Employee details =================================

export const getEmployeeList = async ({ id = "", page = 0, limit = 10, search = "", emp_designation = "" }) => {
  const formData = new FormData();
  if (id !== "") {
    formData.append("id", id);
  }
  if (page !== "") {
    formData.append("page", page);
  }
  if (limit !== "") {
    formData.append("limit", limit);
  }
  if (search !== "") {
    formData.append("search_keyword", search);
  }
  if (emp_designation !== "") {
    formData.append("emp_designation", emp_designation);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/employee/get-employee`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting employee details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// Get API to fetch Venue details =================================

export const getVenueList = async ({ id = "", page = 0, limit = 10, search = "" }) => {
  const formData = new FormData();
  if (id !== "") {
    formData.append("id", id);
  }
  if (page !== "") {
    formData.append("page", page);
  }
  if (limit !== "") {
    formData.append("limit", limit);
  }
  if (search !== "") {
    formData.append("search_keyword", search);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/venue/get-venue`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting venue details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// Get API to fetch Package details =================================
export const getPackageList = async ({ id = "", page = 0, limit = 10, search = "" }) => {
  const formData = new FormData();
  if (id !== "") {
    formData.append("id", id);
  }
  if (page !== "") {
    formData.append("page", page);
  }
  if (limit !== "") {
    formData.append("limit", limit);
  }
  if (search !== "") {
    formData.append("search_keyword", search);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/package/get-package`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting package details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// Get API to fetch Event Type =================================
export const getEventType = async () => {
  // const formData = new FormData();

  // formData.append("type", type);

  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
    // body: formData,
  };

  // const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-categories`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-category`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting event-type details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch  Attire =================================
export const getAttire = async ({ type }) => {
  const formData = new FormData();

  formData.append("type", type);

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-categories`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting attire details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch  new Action =================================
export const getNewAction = async ({ type }) => {
  const formData = new FormData();

  formData.append("type", type);

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-categories`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting next action details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch Events List =================================
export const getEventsList = async (values) => {
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("id", values.id);
  }
  if (values.page !== "") {
    formData.append("page", values.page);
  }
  if (values.limit !== "") {
    formData.append("limit", values.limit);
  }
  if (values.search !== "") {
    formData.append("search_keyword", values.search);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};


// Get API to fetch Events Activity =================================
export const getEventActivity = async (values) => {
  

  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-event-activity`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch getSlotAvailability List =================================
export const getSlotAvailability = async () => {
  

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting slot availability data");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch vender details =================================
export const getVenderList = async ({ id = "", page = 0, limit = 10, search = "" }) => {
  const formData = new FormData();
  if (id !== "") {
    formData.append("vender_id", id);
  }
  if (page !== "") {
    formData.append("page", page);
  }
  if (limit !== "") {
    formData.append("limit", limit);
  }
  if (search !== "") {
    formData.append("search_keyword", search);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/vender/get-vender`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting vender details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// Get API to fetch  Countries =================================
export const getCountries = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(`${baseUrl}/public/countries`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch States =================================
export const getStates = async (values) => {
  
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("country_id", values.id);
    // formData.append("country_code", values.id);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/public/states`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting vender details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch Cities =================================
export const getCities = async (values) => {
  
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("state_id", values.id);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/public/cities`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting vender details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch  planData =================================
export const fetchPlanData = async (values) => {
  const formData = new FormData();

  // formData.append("ordername", "id");
  formData.append("ordervalue", "desc");

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting planData list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

export const fetchSubscriptionData = async () => {
  try {
    const formData = new FormData();
    formData.append("ordername", "id");
    formData.append("ordervalue", "desc");
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-subscription-plans`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Get API to fetch Notifications =================================
export const getNotifications = async (values) => {
  const formData = new FormData();

  if (values.limit) {
    formData.append("limit", values.limit);
  }
  if (values.offset) {
    formData.append("offset", values.offset);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-notification`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting notifications list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// Get API to fetch Enquiry List =================================
export const getEnquiryList = async (values) => {
  const formData = new FormData();
  if (values.id !== "") {
    formData.append("id", values.id);
  }
  if (values.page !== "") {
    formData.append("page", values.page);
  }
  if (values.limit !== "") {
    formData.append("limit", values.limit);
  }
  if (values.search !== "") {
    formData.append("search_keyword", values.search);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-enquiry`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

export const getCompanyProfile = async (values) => {
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  // const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/get-company-profile`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-company-profile`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();

  return res;
};

// Get API to fetch  categories =================================
export const getCategories = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(`${baseUrl}/public/get-category`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch  services =================================
export const getServices = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(`${baseUrl}/public/get-services`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch  genres =================================
export const getGenres = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(`${baseUrl}/public/get-genres`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

// Get API to fetch Enquiry List =================================
export const getAvailableSlot = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(`${baseUrl}/calendar/get-event-time-slot`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};