import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import NavigationFooter from "../components/NavigationFooter";
import Required from "../components/Required";
import { toast } from "react-toastify";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { getNewAction, getEmployeeList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { parseDateString, parseDateStringDisabled, transformData } from "../../utils/Utils";

const EBooking = ({ toggleMain, mainCurrentTab, formik, disabled, prefill, isEventEditing, isViewOnly }) => {
  // const [nextActionOptions, setNextActionsOptions] = useState([]);

  const booking_status = [
    {
      label: "Confirmed",
      value: "1",
    },
    {
      label: "Pending",
      value: "2",
    },
    {
      label: "Completed",
      value: "3",
    },
    {
      label: "On Hold",
      value: "4",
    },
  ];

  //fetching next action data
  const {
    data: nextAction,
    isLoading: nextActionLoading,
    isError: nextActionError,
  } = useQuery({
    queryKey: ["get-new-action"],
    queryFn: () => getNewAction({ type: "3" }),
  });

  const nextActionOptions = transformData({
    dataArray: nextAction?.data?.next_actions || [],
    label: "name",
    value: "id",
    isLoading: nextActionLoading,
  });

  //fetching sales person data
  const {
    data: SalesPerson,
    isLoading: salesPersonloading,
    isError: salesPersonError,
  } = useQuery({
    queryKey: ["get-sales-person"],
    queryFn: () => getEmployeeList({ id: "", page: "", limit: "", search: "", emp_designation: "3" }),
  });

  //options for react select (salesPerson)
  const salesPersonOptions = transformData({
    dataArray: SalesPerson?.data?.employees || [],
    label: "first_name",
    value: "id",
    isLoading: salesPersonloading,
  });

  function handleEventStatus(e) {
    formik.setFieldValue("booking_status", e.value);
  }

  function handleAction(e) {
    formik.setFieldValue("next_action", e.value);
  }

  function handleSalesPerson(e) {
    formik.setFieldValue("sales_person_id", e.value);
  }

  const handelValidation = (callbackFun) => {
    // if (formik.values.booking_status === "") {
    //   toast.error("Boking status is required!");
    //   return;
    // }

    // if (formik.values.next_action === "") {
    //   toast.error("Next action is required!");
    //   return;
    // }
    // if (formik.values.next_action_date === "") {
    //   toast.error("Next action date is required!");
    //   return;
    // }
    // if (formik.values.intial_contract_date === "") {
    //   toast.error("Initial contract date is required!");
    //   return;
    // }
    // if (formik.values.contract_sent_date === "") {
    //   toast.error("Contract sent date is required!");
    //   return;
    // }
    // if (formik.values.contract_due_date === "") {
    //   toast.error("Contract due date is required!");
    //   return;
    // }
    // if (formik.values.booked_date === "") {
    //   toast.error("Booked date is required!");
    //   return;
    // }
    // if (formik.values.inquiry_source === "") {
    //   toast.error("Inquiry source is required!");
    //   return;
    // }
    // if (formik.values.sales_person_id === "") {
    //   toast.error("Sales person is required!");
    //   return;
    // }

    // if (formik.values.rfi_form_id === "") {
    //   toast.error("RFI form ID is required!");
    //   return;
    // }
    // if (formik.values.instant_quote_id === "") {
    //   toast.error("Instant quote ID is required!");
    //   return;
    // }
    callbackFun(true);
  };

  return (
    <>
      <Row className="p-2 py-0">
        {/* First Section */}
        <Col md={6} className="border p-3 bg-white">
          {/* First row */}
          <Row>
            <Col md={6}>
              <div>
                <Label for="booking_status">
                  Booking Status
                  {/* <Required /> */}
                </Label>
                <ReactSelect
                  options={booking_status}
                  id="booking_status"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  name="booking_status"
                  value={formik.values.booking_status}
                  onChange={(e) => handleEventStatus(e)}
                  onBlur={() => formik.setFieldTouched("booking_status", true)}
                />
                {formik.errors.booking_status && formik.touched.booking_status && (
                  <p style={{ color: "red" }}> {formik.errors.booking_status}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                {!nextActionLoading && (
                  <ReactSelect
                    options={nextActionOptions}
                    // updateNextActionOptions={updateNextActionOptions}
                    id="next_action"
                    label=" Next Action"
                    api="https://api.ipartydjcrm.com/api/v1/events/add-categories"
                    addOpetionsForm
                    // disabled={disabled}
                    disabled={isViewOnly}
                    name="next_action"
                    postId="3"
                    value={formik.values.next_action}
                    onChange={(e) => handleAction(e)}
                    onBlur={() => formik.setFieldTouched("next_action", true)}
                  />
                )}

                {formik.errors.next_action && formik.touched.next_action && (
                  <p style={{ color: "red" }}> {formik.errors.next_action}</p>
                )}
              </div>
            </Col>
          </Row>
          {/* Second row */}
          <Row className="mt-3">
            <Col md={6}>
              <div>
                <Label for="nextActionDate">Next Action Date</Label>
                <DatePicker
                  isClearable
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  placeholderText="Next Action Date"
                  id="next_action_date"
                  name="next_action_date"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  dateFormat={"dd/MM/yy"}
                  selected={formik.values.next_action_date ? new Date(formik.values.next_action_date) : ""}
                  onChange={(date) => formik.setFieldValue("next_action_date", date ? date.toISOString() : "")}
                  onBlur={() => formik.setFieldTouched("next_action_date", true)}
                  className="form-control date-picker"
                />
                {formik.errors.next_action_date && formik.touched.next_action_date && (
                  <p style={{ color: "red" }}> {formik.errors.next_action_date}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="initialContractDate">Initial Contract Date</Label>
                <DatePicker
                  isClearable
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  placeholderText="Initial Contract Date"
                  id="intial_contract_date"
                  name="intial_contract_date"
                  dateFormat={"dd/MM/yy"}
                  // disabled={disabled}
                  disabled={isViewOnly}
                  selected={formik.values.intial_contract_date ? new Date(formik.values.intial_contract_date) : ""}
                  onChange={(date) => formik.setFieldValue("intial_contract_date", date ? date.toISOString() : "")}
                  onBlur={() => formik.setFieldTouched("intial_contract_date", true)}
                  className="form-control date-picker"
                />
                {formik.errors.intial_contract_date && formik.touched.intial_contract_date && (
                  <p style={{ color: "red" }}> {formik.errors.intial_contract_date}</p>
                )}
              </div>
            </Col>
          </Row>
          {/* Thirt row */}
          <Row className="mt-3">
            <Col md={6}>
              <div>
                <Label for="contractSent">Contract Sent Date</Label>
                <DatePicker
                  isClearable
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  placeholderText=" Contract Sent  Date"
                  id="contract_sent_date"
                  name="contract_sent_date"
                  dateFormat={"dd/MM/yy"}
                  // disabled={disabled}
                  disabled={isViewOnly}
                  selected={formik.values.contract_sent_date ? new Date(formik.values.contract_sent_date) : ""}
                  onChange={(date) => formik.setFieldValue("contract_sent_date", date ? date.toISOString() : "")}
                  onBlur={() => formik.setFieldTouched("contract_sent_date", true)}
                  className="form-control date-picker"
                />
                {formik.errors.contract_sent_date && formik.touched.contract_sent_date && (
                  <p style={{ color: "red" }}> {formik.errors.contract_sent_date}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="contractDue">Contract Due Date</Label>
                <DatePicker
                  isClearable
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  placeholderText=" Contract Due  Date"
                  id="contract_due_date"
                  name="contract_due_date"
                  dateFormat={"dd/MM/yy"}
                  // disabled={disabled}
                  disabled={isViewOnly}
                  selected={formik.values.contract_due_date ? new Date(formik.values.contract_due_date) : ""}
                  onChange={(date) => formik.setFieldValue("contract_due_date", date ? date.toISOString() : "")}
                  onBlur={() => formik.setFieldTouched("contract_due_date", true)}
                  className="form-control date-picker"
                />
                {formik.errors.contract_due_date && formik.touched.contract_due_date && (
                  <p style={{ color: "red" }}> {formik.errors.contract_due_date}</p>
                )}
              </div>
            </Col>
          </Row>
          {/* forth row */}
          <Row className="mt-3">
            <Col md={12}>
              <Label for="dateBooked">Booked Date</Label>
              <DatePicker
                isClearable
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormatCalendar="MMMM"
                placeholderText="Booked Date"
                id="booked_date"
                name="booked_date"
                // disabled={disabled}
                disabled={isViewOnly}
                dateFormat={"dd/MM/yy"}
                selected={formik.values.booked_date ? new Date(formik.values.booked_date) : ""}
                onChange={(date) => formik.setFieldValue("booked_date", date ? date.toISOString() : "")}
                onBlur={() => formik.setFieldTouched("booked_date", true)}
                className="form-control date-picker"
              />
              {formik.errors.booked_date && formik.touched.booked_date && (
                <p style={{ color: "red" }}> {formik.errors.booked_date}</p>
              )}
            </Col>
          </Row>
        </Col>
        {/* Second Section */}
        <Col md={6} className="border p-3 pt-0 bg-white border-start-0">
          {/* First Row */}
          {/* <Row className="mt-3">
            <Col md={6}>
              <div>
                <Label for="inquirySource">Inquiry Source</Label>
                <Input
                  type="text"
                  id="inquiry_source"
                  name="inquiry_source"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  value={formik.values.inquiry_source}
                  placeholder="Inquiry Source"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.inquiry_source && formik.touched.inquiry_source && (
                  <p style={{ color: "red" }}> {formik.errors.inquiry_source}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label htmlFor="default-4" className="form-label">
                  Sales Person
                </Label>
                <ReactSelect
                  options={salesPersonOptions}
                  id="sales_person_id"
                  name="sales_person_id"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  value={formik.values.sales_person_id}
                  onChange={(e) => handleSalesPerson(e)}
                  onBlur={() => formik.setFieldTouched("sales_person_id", true)}
                />
                {formik.errors.sales_person_id && formik.touched.sales_person_id && (
                  <p style={{ color: "red" }}> {formik.errors.sales_person_id}</p>
                )}
              </div>
            </Col>
          </Row> */}
          {/* Second Row */}
          {/* <Row className="mt-3">
            <Col md={6}>
              <div>
                <Label for="rfiFormId">RFI Form ID</Label>
                <Input
                  type="text"
                  id="rfi_form_id"
                  name="rfi_form_id"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  value={formik.values.rfi_form_id}
                  placeholder="Enter RFI Form ID"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.rfi_form_id && formik.touched.rfi_form_id && (
                  <p style={{ color: "red" }}> {formik.errors.rfi_form_id}</p>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <Label for="instantQuoteId">Instant Quote ID</Label>
                <Input
                  type="text"
                  id="instant_quote_id"
                  name="instant_quote_id"
                  // disabled={disabled}
                  disabled={isViewOnly}
                  value={formik.values.instant_quote_id}
                  placeholder="Enter instant Quote ID"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 50) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.instant_quote_id && formik.touched.instant_quote_id && (
                  <p style={{ color: "red" }}> {formik.errors.instant_quote_id}</p>
                )}
              </div>
            </Col>
          </Row> */}
          {/* third row */}
          {/* <Row className="mt-3">
            <Col md={12}>
              <Label for="notes2">Internal Notes (Booking Comments)</Label>
              <textarea
                maxLength={255}
                class="form-control form-control-sm"
                placeholder="Write your notes"
                name="internal_notes_booking"
                value={formik.values.internal_notes_booking}
                // disabled={disabled}
                disabled={isViewOnly}
                onChange={formik.handleChange}
              />
            </Col>
          </Row> */}
        </Col>
      </Row>

        {
          isViewOnly ? "" :
      <NavigationFooter
      toggle={toggleMain}
      currentState={mainCurrentTab}
      lastPage={"6"}
      validationFunction={handelValidation}
      isViewOnly={disabled}
      formik={formik}
      isEventEditing={isEventEditing}
      />
    }
    </>
  );
};

export default EBooking;
