import Router from "./route/Index";
import "./App.css";
import ThemeProvider from "./layout/provider/Theme";
import ToastLayout from "./components/toast/ToastLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify"; // Import react-toastify
import { getCookie } from "./utils/Utils";

const queryClient = new QueryClient();

export const DataContext = createContext();

const App = () => {
  const [profileprogress, setProfileProgress] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [planEnddates, setPlanEndDates] = useState();
  const [activeTab, setActivetab] = useState("1");
  const [subscribedpalresponce, setSubscribedPalResponce] = useState("")

  useEffect(() => {
    subscribedPlanAPI();
    // Establish a WebSocket connection
    const ws = new WebSocket("ws://localhost:8080"); // Replace with your WebSocket server URL

    // Handle incoming messages
    ws.onmessage = (event) => {
      const messageData = JSON.parse(event.data);

      // Display notification when a new enquiry is received
      if (messageData.type === "new_enquiry") {
        toast.info(messageData.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    };

    // Handle connection closure
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Clean up the WebSocket connection on component unmount
    return () => {
      ws.close();
    };
  }, []);

  const subscribedPlanAPI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-subscribed-plan`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
      });
     
      if (response.ok) {
        const responseData = await response.json();
        setSubscribedPalResponce(responseData.status)
        setPlanEndDates(responseData?.data?.package?.end_date);
      }else {
        throw new Error("Failed to fetch plan data");
      }
      return response;
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
    }

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <DataContext.Provider
          value={{
            profileprogress,
            setProfileProgress,
            hasUnsavedChanges,
            setHasUnsavedChanges,
            planEnddates,
            setPlanEndDates,
            activeTab,
            setActivetab,
            subscribedpalresponce,
            setSubscribedPalResponce,
          }}
        >
          <Router />
          <ToastLayout /> {/* This component will handle displaying the toasts */}
        </DataContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
