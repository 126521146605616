import React, { useEffect, useState } from "react";
import { Icon, Button, Col, RSelect } from "../../../components/Component";
import { Modal, ModalBody, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import Required from "../../components/Required";
import PuffLoader from "react-spinners/PuffLoader";

const FormModal = ({ modal, closeModal, onSubmit, formData, setFormData, modalType, setLoading, loading }) => {
  useEffect(() => {
    reset(formData);
  }, [formData]);
  
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (formData) {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
      setPlanStates(formData.status === "1");
    }
  }, [formData, setValue]);
  const defaultOptions = [
    { value: "m", label: "Month" },
    { value: "d", label: "Days" },
  ];
  const [checkData, setCheckData] = useState({});

  const handleCheckboxChange = (event, feature) => {
    const { checked } = event.target;
    setCheckData((prevFormData) => ({
      ...prevFormData,
      [feature.key]: checked ? "yes" : "no",
    }));
  };
  const [planStates, setPlanStates] = useState(false);

  const handleToggle = () => {
    setPlanStates((prevState) => !prevState);
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: planStates ? "0" : "1",
    }));
  };

  const blockInvalidChar = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.length >= 5 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 17 &&
      e.keyCode !== 65 &&
      e.keyCode !== 9 &&
      e.keyCode !== 86 &&
      e.keyCode !== 88 &&
      e.keyCode !== 67
    ) {
      e.preventDefault();
    }
  };

  const checkKeys = (e) => {
    const inputValue = e.target.value;
    let invalidChars = ["-", "e", "E", ".", "+"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const blockInvalidCharEmp = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.length >= 5 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 17 &&
      e.keyCode !== 65 &&
      e.keyCode !== 9 &&
      e.keyCode !== 86 &&
      e.keyCode !== 88 &&
      e.keyCode !== 67
    ) {
      e.preventDefault();
    }
  };
  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {modalType === "add" && "Add Plan"} {modalType === "edit" && "Update Plan"}
          </h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col>
                <div className="form-group">
                  <label className="form-label">Plan Title</label>
                  <Required />
                  <input
                    type="text"
                    {...register("name", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                    })}
                    maxLength={30}
                    value={formData.name}
                    placeholder="Enter plan title"
                    onChange={(e) => {
                      const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                      setFormData({ ...formData, name: capitalizedValue });
                    }}
                    className="form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Plan Duration</label>
                  <Required />
                  <input
                    type="number"
                    {...register("duration", {
                      required: "This field is required",
                    })}
                    value={formData.duration}
                    maxlength="3"
                    onKeyDown={blockInvalidChar}
                    placeholder="Enter count of days or months"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, duration: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.subtitle && <span className="invalid">{errors.duration.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Select Plan Subscription</label>
                  <Required />
                  <select
                    name="durationtype"
                    id=""
                    selected
                    onChange={(e) => setFormData({ ...formData, durationtype: e.target.value })}
                    className="form-select"
                    defaultValue={"m"}
                  >
                    {defaultOptions.map((item, index) => (
                      <option key={item.value} value={item.value} selected={formData.durationtype === item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Rate Of Plan ($)</label>
                  <Required />
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("rate", { required: "This field is required" })}
                    value={formData.price}
                    placeholder="Enter price"
                    onChange={(e) => {
                      const inputVal = parseInt(e.target.value);
                      const upgradedVal = inputVal <= 1 ? 1 : inputVal;
                      setFormData({ ...formData, price: upgradedVal });
                    }}
                    className="form-control"
                  />
                  {errors.tasks && <span className="invalid">{errors.price.message}</span>}
                </div>
              </Col>
              <Col md="12" className="mt-4">
                <div className="d-flex justify-content-between">
                  <div className="fs-4 fw-bold">Plan Features</div>
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Add Client</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidCharEmp}
                    {...register("client", { required: "This field is required" })}
                    value={formData.client}
                    placeholder="Enter client count"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, client: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.tasks && <span className="invalid">{errors.client.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Add Employee</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidCharEmp}
                    {...register("employee", { required: "This field is required" })}
                    value={formData.employee}
                    placeholder="Enter employee count"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, employee: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.tasks && <span className="invalid">{errors.employee.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Event-Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("event_limit", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.event_limit}
                    placeholder="Enter count for event"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      // Allow empty input (interpreted as "Unlimited")
                      if (inputValue === "") {
                        setFormData({ ...formData, event_limit: "Unlimited" });
                      } else {
                        const parsedValue = parseInt(inputValue, 10);
                        if (!isNaN(parsedValue) && parsedValue >= 0) {
                          setFormData({ ...formData, event_limit: parsedValue });
                        } else {
                          setFormData({ ...formData, event_limit: "Unlimited" });
                        }
                      }
                    }}
                    className="form-control"
                  />
                  {errors.event_limit && <span className="invalid">{errors.event_limit.message}</span>}
                </div>
              </Col>
              <Col md="12" className="mt-2">
                <div className="d-flex overflow-auto h-200px m-3">
                  <div className="row w-100">
                    <div className="col-12 mt-2">
                      <div>
                        <span className="fw-bold">
                          Other Features <br />
                        </span>
                      </div>
                      <ul className="pricing-features">
                        <li>
                          <span className="fs-6">
                            <label className="align-center">
                              <input
                                type="checkbox"
                                className="mx-2"
                                style={{ height: 20, width: 20 }}
                                checked={formData.management === "yes"}
                                value={formData.management}
                                onChange={(e) => {
                                  const value = e.target.checked ? "yes" : "no";
                                  setFormData({ ...formData, management: value });
                                }}
                              />
                              Management
                            </label>
                          </span>
                        </li>
                        <li>
                          <span className="fs-6">
                            <label className="align-center">
                              <input
                                type="checkbox"
                                className="mx-2"
                                style={{ height: 20, width: 20 }}
                                checked={formData.technical_support === "yes"}
                                value={formData.technical_support}
                                onChange={(e) => {
                                  const value = e.target.checked ? "yes" : "no";
                                  setFormData({ ...formData, technical_support: value });
                                }}
                              />
                              Technical Support
                            </label>
                          </span>
                        </li>
                        <li>
                          <span className="fs-6">
                            <label className="align-center">
                              <input
                                type="checkbox"
                                className="mx-2"
                                style={{ height: 20, width: 20 }}
                                checked={formData.website_tool === "yes"}
                                value={formData.website_tool}
                                onChange={(e) => {
                                  const value = e.target.checked ? "yes" : "no";
                                  setFormData({ ...formData, website_tool: value });
                                }}
                              />
                              Website Tool
                            </label>
                          </span>
                        </li>
                        <li>
                          <span className="fs-6">
                            <label className="align-center">
                              <input
                                type="checkbox"
                                className="mx-2"
                                style={{ height: 20, width: 20 }}
                                checked={formData.advanced_features === "yes"}
                                value={formData.advanced_features}
                                onChange={(e) => {
                                  const value = e.target.checked ? "yes" : "no";
                                  setFormData({ ...formData, advanced_features: value });
                                }}
                              />
                              Advanced Features
                            </label>
                          </span>
                        </li>
                        <li>
                          <span className="fs-6">
                            <label className="align-center">
                              <input
                                type="checkbox"
                                className="mx-2"
                                style={{ height: 20, width: 20 }}
                                checked={formData.enhanced_support === "yes"}
                                value={formData.enhanced_support}
                                onChange={(e) => {
                                  const value = e.target.checked ? "yes" : "no";
                                  setFormData({ ...formData, enhanced_support: value });
                                }}
                              />
                              Enhanced Support
                            </label>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col md="12">
                <div className="d-flex justify-content-between flex-row my-4 ">
                  <div className="">
                    <div className="preview-title fw-bold fs-6 col-12">Select The Subscription Status</div>
                    <div className="col-12">
                      <div className="custom-control custom-radio mx-1">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="customRadioPaid"
                          id={`customRadioPaid`}
                          checked={formData.is_paid === "1"}
                          onChange={(e) => {
                            setFormData({ ...formData, is_paid: "1" });
                          }}
                        />
                        <label className="custom-control-label" htmlFor={`customRadioPaid`}>
                          Paid
                        </label>
                      </div>
                      <div className="custom-control custom-radio mx-1">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="customRadioPaid"
                          id={`customRadioFree`}
                          checked={formData.is_paid === "0"}
                          onChange={(e) => {
                            setFormData({ ...formData, is_paid: "0" });
                          }}
                        />
                        <label className="custom-control-label" htmlFor={`customRadioFree`}>
                          Free
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="preview-title fw-bold fs-6 col-12">Select The Plan Status</div>
                    <div className="preview-block">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`toggleSwitch`}
                          checked={planStates}
                          onChange={handleToggle}
                        />
                        <label className="custom-control-label" htmlFor={`toggleSwitch`}>
                          {planStates ? "Active" : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
              <Col size="6"></Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={loading}>
                      {modalType === "add" && "Add Plan"} {modalType === "edit" && "Update Plan"}
                      {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-primary"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FormModal;
