import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "../../components/Component";
import EmploymentAndWages from "../components/CreateEmployeePageForm/EmploymentAndWages";
import Info from "../components/CreateEmployeePageForm/Info";
import Notes from "../components/CreateEmployeePageForm/Notes";
import NavigationHeader from "../components/NavigationHeader";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router";
import Level from "../components/CreateEmployeePageForm/Level";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addEmployee } from "../../http/post/postApi";
import { editEmployeeDetails } from "../../http/edit/editApi";
import { getEmployeeList } from "../../http/get/getApi";
import { PlanContext } from "../context/PlanContext";
import Swal from "sweetalert2";
import { getChangedValues } from "../../utils/Utils";

const CreateEmployeePage = () => {
  const { planState, setPlanState } = useContext(PlanContext);
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  // navigation declrations
  const location = useLocation();
  const navigate = useNavigate();

  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const page = searchParams.get("page");
  const from = searchParams.get("from");

  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;

  const [activeTab, setActivetab] = useState("1");

  const toggleIconTab = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
    }
  };

  const schema = Yup.object().shape({
    // first_name: Yup.string().required("First name field is required."),
    // last_name: Yup.string().required("Last name field is  required."),
    username: Yup.string().required("Username field is required."),
    // country: Yup.string().required("Country field is required."),
    // state: Yup.string().required("State field is required."),
    // city: Yup.string().required("City field is required."),
    password: isEditOnly
      ? Yup.string()
          .nullable()
          .min(8, "Password must be at least 8 characters.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
          )
      : Yup.string()
          .required("Password is required.")
          .min(8, "Password must be at least 8 characters.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
          ),
    email: Yup.string().required("Email field is required.").email("Invalid email address."),
    zip_code: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z0-9]{5,6}$/, "Zipcode must be betwen 5 to 6 digits."),
    work_phone_number: Yup.string()
      .required("Work phone number field is required.")
      .matches(/^[0-9]{9,13}$/, "Work phone no must be between 9 and 13 digits."),
    home_phone_number: Yup.string()
      .nullable()
      .matches(/^[0-9]{9,13}$/, "Home phone no must be between 9 to 13 digits."),
    phone_number: Yup.string()
      .nullable()
      .matches(/^[0-9]{9,13}$/, "Mobile phone no must be between 9 to 13 digits."),
      website: Yup.string()
      .nullable()
      .matches(/^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})([\/\w .-]*)*\/?$/,
    "Please enter a valid website URL."),
  });

  let initialValues = {
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    zip_code: "",
    country: "",
    state: "",
    files: "",
    email: "",
    phone_number: "",
    work_phone_number: "",
    home_phone_number: "",
    appointment_link: "",
    skype_id: "",
    website: "",
    dob: "",
    biography: "",
    notes: "",
    emp_number: "",
    start_hired_date: "",
    emp_type: "",
    emp_category: "",
    began_profession: "",
    no_event_done: "",
    emp_experience: "",
    emp_pay_rate: "",
    calculation_method: "",
    hourly_rate: "",
    emp_designation: "",
  };
  

  const {
    data: employeeList,
    isLoading: employeeIsLoading,
    isError: employeeListIsError,
  } = useQuery({
    queryKey: ["get-employee-by-id", id],
    queryFn: () => getEmployeeList({ id: id }),
  });

  //Mutation hook for adding employee
  const {
    mutate: addNewEmployee,
    isLoading: isEmployeeAdding,
    error: employeeAddError,
  } = useMutation({
    mutationKey: ["Add-client"],
    mutationFn: (values) => addEmployee(values),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        navigate(`/employee-list/create-employee?type=edit&id=${data.data[0].id}`);
      } else if (data.errors) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.errors[0],
          focusConfirm: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message,
          focusConfirm: false,
        });
      }
    },
  });
  const addnewEmployee = (values) => {
    try {
      if (planState === "true") {
        addNewEmployee(values);
      } else {
        navigate("/plan-selection");
      }
    } catch (error) {
      console.error("Error adding employee:", error);
    }
  };

  //Mutation hook for editing employee
  const {
    mutate: editEmployeeMutation,
    isLoading: isEmployeeEditing,
    error: emloyeeEditError,
  } = useMutation({
    mutationKey: ["Edit-employee-by-ID"],
    mutationFn: (value) => editEmployeeDetails(value, id),
    onSuccess: (data) => {
      // if (!data.errors && data.status && !hasInvalidated) {
      //   queryClient.invalidateQueries(["get-employee-by-id", id]);
      //   setHasInvalidated(true);
        if (!data.errors && data.status) {
        if (activeTab === "4") {
          // if (window.history.length > 1) {
            if (from === "fromEdit" || page === "employee") {
              if (from === "fromEdit") {
                toast.success("Employee Updated Succesfully");
              } else {
                toast.success("Employee Added Succesfully");
              }
              // navigate(-1);
              navigate("/employee-list");
            } else {
              // navigate(-2);
              navigate("/employee-list");
              toast.success("Employee Added Succesfully");
            }
          // }
        }
        queryClient.invalidateQueries(["get-employee-by-id"]);
      }
    },
  });
  const editEmployeeFromList = (value) => {
    try {
      editEmployeeMutation(value);
    } catch (error) {
      console.error("Error editing employee:", error);
    }
  };

  useEffect(() => {
    if (!employeeIsLoading && isEditOnly || isViewOnly) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...employeeList?.data?.employees[0],
          appointment_link: employeeList?.data?.employees[0]?.appointment_id,
          files: employeeList?.data?.employees[0]?.profile_image_path,
          country: parseInt(employeeList?.data?.employees[0]?.country),
          state: parseInt(employeeList?.data?.employees[0]?.state),
          city: parseInt(employeeList?.data?.employees[0]?.city),
          began_profession: parseInt(employeeList?.data?.employees[0]?.began_profession),
        };
      });
    }
  }, [employeeIsLoading, employeeList]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      if (!isEditOnly) {
        addnewEmployee(value);
      } else if (isEditOnly) {
        // editEmployeeFromList(value);
        editEmployeeFromList(getChangedValues(employeeList?.data?.employees[0], formik.values));
      }
    },
  });
  
  return (
    <>
      <NavigationHeader
        heading={from === "fromEdit" ? " Edit Employee" : from === "fromView" ? "Employee Details" : "Add Employee"}
        buttonInnerText={"Back"}
        route={"/employee-list"}
        back
      />
      <section className="m-4 mt-2 border p-3 pt-0 bg-white" style={{ height: "auto" }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "1" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("1");
              }}
            >
              <Icon name="user" /> <span>Basic Information</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("2");
              }}
            >
              <Icon name="article" /> <span>Notes</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "3" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("3");
              }}
            >
              <Icon name="calendar" /> <span>Employement And Wages</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("4");
              }}
            >
              <Icon name="info-i" /> <span>Employement level</span>
            </NavLink>
          </NavItem>
        </Nav>
        <form onSubmit={formik.handleSubmit} className="mt-3">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Info
                toggle={toggleIconTab}
                currentState={activeTab}
                formik={formik}
                isEditOnly={isEditOnly}
                employeeIsLoading={employeeIsLoading}
                isEmployeeAdding={isEmployeeAdding}
                isViewOnly={isViewOnly}
              />
            </TabPane>
            <TabPane tabId="2">
              <Notes toggle={toggleIconTab} currentState={activeTab} formik={formik} isViewOnly={isViewOnly} isEmployeeEditing={isEmployeeEditing}/>
            </TabPane>
            <TabPane tabId="3">
              <EmploymentAndWages toggle={toggleIconTab} currentState={activeTab} formik={formik}  isViewOnly={isViewOnly} isEmployeeEditing={isEmployeeEditing}/>
            </TabPane>
            <TabPane tabId="4">
              <Level
                toggle={toggleIconTab}
                currentState={activeTab}
                formik={formik}
                // isEmployeeAdding={isEmployeeAdding}
                isEmployeeEditing={isEmployeeEditing}
                isViewOnly={isViewOnly}
              />
            </TabPane>
          </TabContent>
        </form>
      </section>
    </>
  );
};
export default CreateEmployeePage;
