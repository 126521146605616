import NavigationFooter from "../NavigationFooter";
import React, { useEffect, useState } from "react";
import { Input, Label, Row, Col, Button } from "reactstrap";

import UploadImage from "../UploadImage";
import Required from "../Required";
import { toast } from "react-toastify";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import { useQuery } from "react-query";
import { getCities, getCountries, getStates } from "../../../http/get/getApi";
import { transformData } from "../../../utils/Utils";
import { useParams } from "react-router";
import { Icon } from "../../../components/Component";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Info = ({ toggle, currentState, formik, isEditOnly, employeeIsLoading, isEmployeeAdding, isViewOnly }) => {
  const { id } = useParams();

  const AddEmployeeModal = () => {
    setIsOpen(!isOpen);
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }
  const [isOpen, setIsOpen] = useState(false);

  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
  });

  //optiopns for countries dropdown
  const countriesOptions = transformData({
    dataArray: country?.data?.countries,
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values.country;
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  let stateId = formik.values.state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-states-by-country", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  // onChangeFunctions
  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
  };

  const handelValidation = (callbackFun) => {
    if (formik.values.username === "") {
      formik.setFieldTouched("username", true);
    }
    // if (formik.values.first_name === "") {
    //   formik.setFieldTouched("first_name", true);
    // }

    // if (formik.values.last_name === "") {
    //   formik.setFieldTouched("last_name", true);
    // }

    if (formik.values.email === "") {
      formik.setFieldTouched("email", true);
    }
    if (formik.values.email === "") {
      formik.setFieldTouched("email", true);
    }

    if (formik.values.password === "" && !isEditOnly) {
      formik.setFieldTouched("password", true);
    }

    if (formik.values.work_phone_number === "") {
      formik.setFieldTouched("work_phone_number", true);
    }

    // if (formik.values.country === "") {
    //   formik.setFieldTouched("country", true);
    // }

    // if (formik.values.state === "") {
    //   formik.setFieldTouched("state", true);
    // }

    // if (formik.values.city === "") {
    //   formik.setFieldTouched("city", true);
    // }

    if (formik.errors.password && formik.values.password !== "" && !isEditOnly) {
      toast.warning("Invalid password.");
      return;
    }
    if (formik.errors.email && formik.values.email !== "") {
      toast.warning("Invalid email.");
      return;
    }
    if (formik.errors.work_phone_number && formik.values.work_phone_number !== "") {
      toast.warning("Invalid work phone number !");
      return;
    }

    if (formik.errors.website && formik.values.website !== "") {
      toast.warning("Invalid website url !");
      return;
    }

    // if (formik.errors.work_phone_number && formik.values.work_phone_number !== "") {
    //   toast.warning("Invalid work phone number.");
    //   return;
    // }

    if (
      formik.values.username !== "" &&
      // formik.values.first_name !== "" &&
      // formik.values.last_name !== "" &&
      formik.values.email !== "" &&
      // formik.values.country !== "" &&
      // formik.values.state !== "" &&
      // formik.values.city !== "" &&
      formik.values.work_phone_number !== "" &&
      (isEditOnly || formik.values.password !== "")
    ) {
      callbackFun(true);
    }
  };

  return (
    <div>
      {employeeIsLoading && isEditOnly ? (
        <Row>
          <Col md={6} className="border p-3 bg-white">
            <Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <ShimmerThumbnail height={250} width={300} rounded />
                <ShimmerThumbnail height={40} width={300} rounded />
              </Col>
            </Row>
          </Col>
          <Col md={6} className="border p-3 bg-white">
            <Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={200} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={200} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={200} rounded />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="p-2 py-0">
          {/* First Section */}
          <Col md={6} className="border p-3 bg-white">
          <Row className="mt-4">
              <Col md={6}>
                <UploadImage label="Upload profile picture" name="files" formik={formik} isViewOnly={isViewOnly} />
              </Col>
              <Col md={4}>
                
                <div>
                  <Label for="firstname">
                    First Name
                    {/* <Required /> */}
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    id="firstname"
                    placeholder="Enter first name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.first_name && formik.touched.first_name}
                  />
                  {/* {formik.errors.first_name && formik.touched.first_name && (
                    <p style={{ color: "red" }}> {formik.errors.first_name}</p>
                  )} */}
                </div>
                <div className="mt-3">
                  <Label for="lastname">
                    Last Name
                    {/* <Required /> */}
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    id="lastname"
                    placeholder="Enter last name"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.last_name && formik.touched.last_name}
                  />
                  {/* {formik.errors.last_name && formik.touched.last_name && (
                    <p style={{ color: "red" }}> {formik.errors.last_name}</p>
                  )} */}
                </div>
                <div className="mt-3">
                  <Label for="website">Website</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="website"
                    placeholder="Enter website"
                    name="website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.website && !isEditOnly && formik.touched.website && (
                    <p style={{ color: "red" }}> {formik.errors.website}</p>
                  )}
                </div>
              </Col>
              
            </Row>
            
            {/* <Row>

            <Col md={4}>
                <div>
                  <Label for="middlename">
                    Username
                    <Required />
                  </Label>
                  <Input
                    disabled={isEditOnly}
                    id="username"
                    placeholder="Enter username"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.username && formik.touched.username}
                  />
                  {formik.errors.username && formik.touched.username && (
                    <p style={{ color: "red" }}> {formik.errors.username}</p>
                  )}
                </div>
              </Col>
            <Col md={4}>
                <div>
                  <Label for="firstname">
                    First Name
                    <Required />
                  </Label>
                  <Input
                    id="firstname"
                    placeholder="Enter first name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.first_name && formik.touched.first_name}
                  />
                  {formik.errors.first_name && formik.touched.first_name && (
                    <p style={{ color: "red" }}> {formik.errors.first_name}</p>
                  )}
                </div>
              </Col>

              <Col md={4}>
                <div>
                  <Label for="lastname">
                    Last Name
                    <Required />
                  </Label>
                  <Input
                    id="lastname"
                    placeholder="Enter last name"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.last_name && formik.touched.last_name}
                  />
                  {formik.errors.last_name && formik.touched.last_name && (
                    <p style={{ color: "red" }}> {formik.errors.last_name}</p>
                  )}
                </div>
              </Col>
            </Row> */}

            <Row className="mt-4">

            <Col md={4}>
                
              </Col>

              <Col md={4}>
                
              </Col>

    
            </Row>

            <Row className="mt-4">
            <Col md={4}>
                <div>
                  <Label for="homeno">Home Number</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="homeno"
                    type="number"
                    placeholder="Enter home phone no"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    name="home_phone_number"
                    value={formik.values.home_phone_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.home_phone_number && formik.touched.home_phone_number && (
                    <p style={{ color: "red" }}> {formik.errors.home_phone_number}</p>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="workno">
                    Work Number
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    id="workno"
                    type="number"
                    placeholder="Enter work phone no"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    name="work_phone_number"
                    value={formik.values.work_phone_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.work_phone_number && formik.touched.work_phone_number}
                  />
                  {formik.errors.work_phone_number && formik.touched.work_phone_number && (
                    <p style={{ color: "red" }}> {formik.errors.work_phone_number}</p>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="mobileno">Mobile Number</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="mobileno"
                    type="number"
                    placeholder="Enter mobile phone no"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    name="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.phone_number && formik.touched.phone_number && (
                    <p style={{ color: "red" }}> {formik.errors.phone_number}</p>
                  )}
                </div>
              </Col>
              
            </Row>

            

            {/* <Row className="mt-4">
              <Col md={6}>
                <UploadImage label="Upload profile picture" name={"files"} formik={formik} />
              </Col>
            </Row> */}
          </Col>

          {/* Second Section */}
          <Col md={6} className="border p-3 bg-white border-start-0">

          <Row>
            <Col md={4}>
            <div>
                  <Label for="email">
                    Email
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    disabled={isEditOnly}
                    type="email"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.email && formik.touched.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p style={{ color: "red" }}> {formik.errors.email}</p>
                  )}
                </div>
              </Col>
              <Col md={4}>
              <div>
                  <Label for="middlename">
                    Username
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    disabled={isEditOnly}
                    id="username"
                    placeholder="Enter username"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.username && formik.touched.username}
                  />
                  {formik.errors.username && formik.touched.username && (
                    <p style={{ color: "red" }}> {formik.errors.username}</p>
                  )}
                </div>
              </Col>
              <Col md={4}>
              <div className="form-control-wrap">
                  <Label for="pass" className="d-flex justify-content-between align-items-center">
                    <div>
                      Password
                      <Required />
                    </div>
                    {id && (
                      <span class="badge bg-primary" onClick={AddEmployeeModal}>
                        Update password
                      </span>
                    )}
                  </Label>
                  <div className="form-icon form-icon-right mt-4">
                    <Icon
                      name={isPasswordVisible ? "ni ni-eye" : "ni ni-eye-off"}
                      className="icon mt-2"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Input
                    readOnly={isViewOnly}
                    type={isPasswordVisible ? "text" : "Password"}
                    id="password"
                    name="password"
                    className="form-control"
                    value={formik.values.password}
                    disabled={id ? true : false}
                    placeholder={isPasswordVisible ? "Enter password" : "● ● ● ● ● ● ●"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.password && formik.touched.password}
                  />
                  {formik.errors.password && !isEditOnly && formik.touched.password && (
                    <p style={{ color: "red" }}> {formik.errors.password}</p>
                  )}
                </div>
              </Col>
              
            </Row>

            <Row className="mt-4">

            <Col md={4}>
                <div>
                  <Label for="address_1">Address 1</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="address_1"
                    placeholder="Enter address_1"
                    name="address_1"
                    value={formik.values.address_1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div>
                  <Label for="address_2">Address 2</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="address_2"
                    placeholder="Enter address_2"
                    name="address_2"
                    value={formik.values.address_2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div>
                  <Label for="zip_code">Zip-code</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="zip_code"
                    type="number"
                    placeholder="Enter zip code"
                    name="zip_code"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.zip_code && formik.touched.zip_code && (
                    <p style={{ color: "red" }}> {formik.errors.zip_code}</p>
                  )}
                </div>
              </Col>

            </Row>

            <Row className="mt-4">
              <Col md={4}>
                <div>
                  <Label for="country">Select Country 
                    {/* <Required /> */}
                    </Label>
                  <ReactSelect
                    disabled={isViewOnly}
                    options={countriesOptions}
                    id="country"
                    name="country"
                    value={formik.values.country}
                    onChange={(e) => handelCountryDropdown(e)}
                    onBlur={() => formik.setFieldTouched("country", true)}
                    invalid={formik.errors.country && formik.touched.country}
                  />
                  {/* {formik.errors.country && formik.touched.country && (
                    <p style={{ color: "red" }}>{formik.errors.country}</p>
                  )} */}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="state">Select State 
                    {/* <Required /> */}
                    </Label>
                  <ReactSelect
                    disabled={isViewOnly}
                    options={statesOptions}
                    id="state"
                    name="state"
                    value={formik.values.state}
                    onChange={(e) => handelStateDropdown(e)}
                    onBlur={() => formik.setFieldTouched("state", true)}
                    invalid={formik.errors.state && formik.touched.state}
                  />
                  {/* {formik.errors.state && formik.touched.state && <p style={{ color: "red" }}>{formik.errors.state}</p>} */}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="city">Select City 
                    {/* <Required /> */}
                    </Label>
                  <ReactSelect
                    disabled={isViewOnly}
                    options={citiesOptions}
                    id="city"
                    name="city"
                    value={formik.values.city}
                    onChange={(e) => handelCityDropdown(e)}
                    onBlur={() => formik.setFieldTouched("city", true)}
                    invalid={formik.errors.city && formik.touched.city}
                  />
                  {/* {formik.errors.city && formik.touched.city && <p style={{ color: "red" }}>{formik.errors.city}</p>} */}
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={4}>
                <div>
                  <Label for="appoinment">Appointment Link</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="appoinment"
                    placeholder="Enter appointment link"
                    name="appointment_link"
                    value={formik.values.appointment_link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="skype">Skype</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="skype_id"
                    placeholder="Enter skype id"
                    name="skype_id"
                    value={formik.values.skype_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          {isViewOnly ? "" :
          <NavigationFooter
            toggle={toggle}
            currentState={currentState}
            lastPage={"4"}
            validationFunction={handelValidation}
            formik={formik}
            isEmployeeAdding={isEmployeeAdding}
          />
          }
        </Row>
      )}
    </div>
  );
};
export default Info;
