import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { getCookie } from "../../../utils/Utils";

export const solidLineChart = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "BTC",
  lineTension: 0.4,
  legend: true,
  categoryPercentage: 0.9,
  barPercentage: 0.6,
  datasets: [
    {
      label: "Wedding",
      borderColor: "#5ce0aa",
      backgroundColor: "white",
      pointBorderWidth: 2,
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
    {
      label: "Anniversary",
      backgroundColor: "white",
      pointBorderWidth: 2,
      borderColor: "#798bff",
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82],
    },
    {
      label: "Birthday",
      borderColor: "#df8e4a",
      backgroundColor: "white",
      pointBorderWidth: 2,
      fill: false,
      categoryPercentage: 0.3,
      barPercentage: 0.6,
      data: [80, 110, 95, 125, 55, 110, 90, 75, 80, 95, 125, 55],
    },
  ],
};

export const LineChartExample = ({ data, legend }) => {
  return (
    <Line
      className="line-chart"
      data={data}
      options={{
        plugins: {
          legend: {
            display: legend,
            labels: {
              boxWidth: 12,
              padding: 20,
              fontColor: "#6783b8",
            },
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: "13px",
            },
            titleColor: "#6783b8",
            titleMarginBottom: 6,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: "12px",
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              beginAtZero: false,
              color: "#9eaecf",
              font: {
                size: "12px",
              },
              padding: 10,
            },
            grid: {
              tickMarkLength: 0,
            },
          },
          x: {
            display: true,
            ticks: {
              color: "#9eaecf",
              font: {
                size: "12px",
              },
              source: "auto",
              padding: 5,
            },
            grid: {
              color: "transparent",
              tickMarkLength: 10,
              offsetGridLines: true,
            },
          },
        },
      }}
    />
  );
};
