import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  PreviewCard,
} from "../../components/Component";
import MonthlyGraph from "./Monthly_Sales/MonthlyGraph";
import TotalEvent from "./TotalEvent";
import TotalWages from "./TotalWages";
import DayToDayGraph from "./Daily_Sales/DayToDayGraph";
import NewsUsers from "./User";
import RecentActivity from "./Activity";
import UpcomingEvents from "./UpcomingEvents";
import EventComparison from "./Event Graph/EventComparison";
import { PlanContext } from "../context/PlanContext";
import { useNavigate } from "react-router";
import { getCompanyProfile } from "../../http/get/getApi";
import Cookies from "js-cookie";
import { DataContext } from "../../App";
import NavigationHeader from "../components/NavigationHeader";

const HomePage = () => {
  const { setProfileProgress, profileprogress } = useContext(DataContext);

  const [sm, updateSm] = useState(false);
  const { planState, setPlanState } = useContext(PlanContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (planState === false) {
      navigate("/plan-selection");
    }

    const updateProfile = async () => {
      const data = await getCompanyProfile();
      // const userID = data?.data?.profile?.user_id ? JSON.parse(data?.data?.profile?.user_id) : 0;
      // Cookies.set("userID", userID);
      const profileProgress = data?.data?.profile?.profile_progress
        ? JSON.parse(data?.data?.profile?.profile_progress)
        : 0;
      setProfileProgress(profileProgress);
    
    };
    updateProfile();

  }, [planState]);

  return (
    <React.Fragment>
      <Head title="Default Dashboard" />
      {/* <Content> */}
      <NavigationHeader
        heading={"Overview"}
        
      />
        {/* <BlockHead size="sm" className="p-0">
          <div className="nk-block-between align-content-center p-1">
            <BlockHeadContent>
              <BlockTitle page tag="h3" className="mt-3 me-1">
                Overview
              </BlockTitle>
              <BlockDes className="text-soft">
              </BlockDes>
            </BlockHeadContent>
          </div>
        </BlockHead> */}
        <Block className="m-4">
          {/* <Row className="g-gs"> */}
          <Row className="g-2">
            <Col xxl="6" lg={6} md={6}>
              {/* <Row className="g-gs justify-around"> */}
              <Row className="g-2 justify-around">
                <Col md={12} lg={12}>
                  <PreviewAltCard className="card-bordered h-100">
                    <TotalEvent />
                  </PreviewAltCard>
                </Col>
                <Col md={12} lg={12}>
                  <PreviewAltCard className="card-bordered h-100">
                    <TotalWages />
                  </PreviewAltCard>
                </Col>
              </Row>
            </Col>
            <Col xxl="6" lg={6} md={6}>
              {/* <Row className="g-gs"> */}
              <Row className="g-2">
                <Col lg={12} md={12} xxl="12">
                  <PreviewAltCard className="card-bordered h-100">
                    <MonthlyGraph />
                  </PreviewAltCard>
                </Col>
                <Col lg={12} md={12} xxl="12">
                  <PreviewAltCard className="card-bordered h-100">
                    <DayToDayGraph />
                  </PreviewAltCard>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="g-gs my-2 "> */}
          <Row className="g-2 my-2 ">
            <Col xxl="12" className="m-0">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <EventComparison />
              </Card>
            </Col>
          </Row>
          {/* <Row className="g-gs"> */}
          <Row className="g-2 mb-2">
            <Col xxl="8" lg={8} md={8} className="">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <NewsUsers />
              </Card>
            </Col>
            <Col xxl="4" lg={4} md={4}>
              <Card className="card-bordered card-full overflow-auto">
                <RecentActivity />
              </Card>
            </Col>
            <Col xxl="12" xl="12" lg="12" md="12">
              <Card className="card-bordered card-full overflow-auto">
                <UpcomingEvents />
              </Card>
            </Col>
          </Row>
        </Block>
      {/* </Content> */}
    </React.Fragment>
  );
};

export default HomePage;
