import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

function SuspendPage({ isOpen }) {
  return (
    <Modal isOpen={isOpen} backdrop="static" centered>
    <ModalHeader ><span style={{color:"red"}}>You are Suspended</span></ModalHeader>
    <ModalBody>
        <p>Your account has been suspended.</p>
        <p>Please contact your administrator for further assistance.</p>
        <p>You can reach us at : <span style={{color:"blue"}}> admin@gmail.com</span></p>
      </ModalBody>
    <ModalFooter>
    </ModalFooter>
  </Modal>
  );
}

export default SuspendPage;
