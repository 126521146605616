import React, { useEffect, useState } from "react";
import { DailyReportEvent } from "./DailyReportEvent";
import { getCookie } from "../../../utils/Utils";
import { Link } from "react-router-dom";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const DayToDayGraph = () => {
  const [eventdata, setEventData] = useState([]);
  const [isEventPresent, setIsEventPresent] = useState(true);
  const [auOverview, setAuOverview] = useState("7 days");
  const [registeredCompanies, setRegisteredCompanies] = useState(0);


  useEffect(() => {
    const countfilterdata = eventdata?.datasets?.[0]?.data;
    if (countfilterdata) {
      let companyCount = 0;

      for (let i = 0; i < countfilterdata.length; i++) {
        companyCount += countfilterdata[i];
      }

      setRegisteredCompanies(companyCount);
    } else {
      setRegisteredCompanies(0); // or any default value if filterData.datasets[0].data is undefined
    }
  }, [eventdata]); // Dependency array to watch for changes in filterData

  useEffect(() => {
    eventInfo();
    return () => {};
  }, [auOverview]);
  const eventInfo = async () => {
    const formData = new FormData();
  formData.append("type", auOverview);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/client/get-company-client`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: formData
      });
      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      // console.log("response***",responseData)
      if (responseData.status === false) {
        setIsEventPresent(false);
        return null;
      }
      setEventData(responseData ? responseData?.data : []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
        <div className="card-title">
          {/* <h4 className="title"> {auOverview === "7 days" ? "Weekly " : "Monthly "} Client Registration</h4> */}
          <h4 className="title">Client Registration</h4>
          <p>
            In {auOverview === "7 days" ? "7 days " : "30 days "}client registered our company.{" "}
            <Link to="/client-list">See Full Details</Link>
          </p>
        </div>
        <div className="card-tools">
          <div className="card-tools shrink-0 w-150px d-sm-block">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "7 days" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("7 days");
                  }}
                >
                  7 D
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "1 month" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("1 month");
                  }}
                >
                  1 M
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-sale-data-group align-center justify-between gy-3 gx-5 row">
        <div className="nk-sale-data col-8">
          <div className="amount sm row">
            <span className="col-6 text-body">Total Count of Clients:</span>
            <span className="col-6 px-1 text-body">
              {registeredCompanies} <small>Subscribers</small>
            </span>
          </div>
        </div>
      </div>
      <div className="nk-sales-ck large pt-4 h-200px">
        <DailyReportEvent auOverview={auOverview} eventdata={eventdata}/>
      </div>
    </React.Fragment>
  );
};
export default DayToDayGraph;
