import React, { useEffect, useState } from "react";
import { CardTitle} from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { getEventActivity, getEventType, getEventsList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { PaginationComponent } from "../../components/Component";

const RecentActivity = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(5);
  const { data: eventType } = useQuery({
    queryKey: ["get-event-type"],
    queryFn: () => getEventType({ type: "1" }),
  });
  const { data: eventList } = useQuery({
    queryKey: ["get-event-list"],
    queryFn: () => getEventActivity(),
    staleTime: 5000,
    cacheTime: 300000, 
    retry: 1,
  });

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  // const currentItems = eventType ? eventType?.data?.event_type?.slice(indexOfFirstItem, indexOfLastItem) : [];
  const currentItems = eventList ? eventList : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
console.log("currentItems",currentItems)
  let eventCategoryTotal = 0;
  for (let i = 0; i < eventType?.data?.event_type?.length; i++) {
    eventCategoryTotal++;
  }

  function calculateEventTypeNumber(eventyTypeName) {
    let event = 0;
    for (let i = 0; i < eventList?.data.length; i++) {
      if (eventList?.data?.[i]?.event_type_name === eventyTypeName) {
        event++;
      }
    }
    return event;
  }
  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <h4 className="me-2">Event Activities</h4>{" "}
              <div className="fs-14px fw-normal mt-3 text-body">Total Event Types : {eventCategoryTotal} </div>
            </h6>
          </CardTitle>
        </div>
      </div>
      <DataTableBody className="border-top h-100" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead ">Sr. No.</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">Event Name</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            {/* <span className="text-dark tb-lead">Total Count</span> */}
            <span className="text-dark tb-lead">Event Count</span>
          </DataTableRow>
        </DataTableHead>
        {/* {currentItems?.data?.map((item, idx) => {
          const eventcount = calculateEventTypeNumber(item.name);
          return (
            <DataTableItem key={idx}>
              <DataTableRow className="text-center">
                <span className="tb-sub">
                  <a href="#order" className="text-dark">{idx + 1}</a>
                </span>
              </DataTableRow>
              <DataTableRow className="text-center">
                <span className="tb-sub text-dark">{item.name}</span>
              </DataTableRow>
              <DataTableRow className="text-center">
                <span className="tb-sub text-dark">{eventcount}</span>
              </DataTableRow>
            </DataTableItem>
          );
        })} */}
      </DataTableBody>
      {/* {eventType?.data?.event_type?.length === 0 ? null : ( */}
        <div className="my-3 mx-auto">
          {/* <PaginationComponent
            itemPerPage={itemPerPage}
            totalItems={eventType?.data?.event_type?.length || 0}
            paginate={paginate}
            currentPage={currentPage}
          /> */}
        </div>
      {/* )} */}
    </React.Fragment>
  );
};
export default RecentActivity;
